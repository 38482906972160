import React, { useEffect, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
import SideMenu from "../sidemenu";
import "./index.scss";
import { SidebarSection } from "../../constants/constant";
import SideBarHeader from "../header/LoggedInHeader";
import api from "../../api";
import DishRegister from "./registerDish";
import Loader from "../Loader";
import Pagination from "./pagination";
import { DishDetailsInterface, DishInterface } from "./interface";
// import DishUpdate from "./updateDish";
import DishDetailView from "./dishDetailView";
import { ToastContainer, toast } from "react-toastify";
import { Button, Spin, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Resizer from "react-image-file-resizer";

// declare var bootstrap: any;

function Dish() {
  // const [count, setCount] = useState(1);

  const [showNav, setShowNav] = useState(true);
  const [loading, setLoading] = useState(true);
  // const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // const [searchText, setSearchText] = useState("");
  const [dishData, setDishData] = useState<DishInterface[]>([]);
  const [dishDetailData, setDishDetailData] =
    useState<DishDetailsInterface | null>(null);
  const [dishId, setDishId] = useState<string | null>(null);
  // const [actionType, setActionType] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [dishStatus, setDishStatus] = useState("");
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [uploadImageModal, setUploadImageModal] = useState(false);
  const [defaultCurrency, setDefaultCurrency] = useState("");
  const [search, setSearch] = useState("");
  const ITEMS_PER_PAGE = 10;
  // update dis state
  const keys = {
    images: "images",
    videos: "videos",
  };

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    productionCost: 0,
    preparationTime: 0,
    servingSize: 0,
    categoryTag: [""],
    ingrediants: [{ name: "", description: "", quantity: 0, unit: "" }],
  });
  useEffect(() => {
    // Set initial form data when dishDetailData is available

    if (dishDetailData) {
      setFormData({
        name: dishDetailData.name,
        description: dishDetailData.description,
        productionCost: dishDetailData.productionCost,
        preparationTime: dishDetailData.preparationTime,
        servingSize: dishDetailData.servingSize,
        categoryTag: dishDetailData.categoryTag,
        ingrediants: dishDetailData.ingrediants.map((ingredient) => ({
          name: ingredient.name,
          description: ingredient.description,
          quantity: ingredient.quantity,
          unit: ingredient.unit,
        })),
      });
    }
  }, [dishDetailData]);
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value;
    setSearch(searchText);
    setCurrentPage(1); // Reset to the first page whenever search text changes
  };

  // const props = (key: any) => ({
  //   name: key,
  //   //action:process.env.REACT_APP_API_URL + "api/utility/dish/" + dishId,
  //   action: "/api/utility/dish/" + dishId,
  //   method: "PUT",
  //   headers: {
  //     authorization: "authorization-text",
  //   },
  //   multiple: true,
  //   onChange(info: any) {
  //     if (info.file.status !== "uploading") {
  //       console.log(info.file, info.fileList);
  //     }
  //     if (info.file.status === "done") {
  //       message.success(`${info.file.name} File uploaded successfully`);

  //     } else if (info.file.status === "error") {
  //       message.error(`${info.file.name} File upload failed.`);
  //     }
  //   },
  // });

  const [imageList, setImageList] = useState([]);
  const [videoList, setVideoList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dataURLtoBlob = (dataURL) => {
    const [header, data] = dataURL.split(",");
    const mime = header.match(/:(.*?);/)[1];
    const binary = atob(data);
    const array = [];

    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }

    return new Blob([new Uint8Array(array)], { type: mime });
  };

  const handleFileChange = async (key, { fileList, file }) => {
    if (key === "images") {
      // Check if the action is "removed" (deletion)
      if (file.status === "removed") {
        setImageList(fileList); // Update imageList with the remaining files after deletion
        return;
      }

      let dimensionErrorShown = false;
      let sizeErrorShown = false;

      // Filter valid images by checking file size and dimensions for new uploads only (those with originFileObj)
      const validImages = await Promise.all(
        fileList
          .filter((file) => file.originFileObj)
          .map((file) => {
            return new Promise((resolve) => {
              // Check file size before checking dimensions
              if (file.size / 1048576 > 1) {
                if (!sizeErrorShown) {
                  message.error("Image size cannot exceed 1 MB.");
                  sizeErrorShown = true; // Prevent duplicate error messages
                }
                resolve(null); // Exclude large image
                return;
              }

              const img = new Image();
              const objectUrl = URL.createObjectURL(file.originFileObj);

              img.onload = () => {
                if (img.width >= 512 && img.height >= 512) {
                  resolve(file);
                } else {
                  if (!dimensionErrorShown) {
                    message.error(
                      "Image dimensions must be at least 512x512 pixels."
                    );
                    dimensionErrorShown = true; // Prevent duplicate dimension messages
                  }
                  resolve(null); // Exclude image with invalid dimensions
                }
                URL.revokeObjectURL(objectUrl); // Clean up object URL
              };

              img.src = objectUrl; // Trigger image load
            });
          })
      );

      // Filter out null values (invalid images due to size or dimensions)
      const filteredImages = validImages.filter((image) => image !== null);

      // Resize images to 512x512 for new uploads only (those with originFileObj)
      const resizedImagesPromises = filteredImages.slice(0, 3).map(
        (file) =>
          new Promise((resolve) => {
            if (file.originFileObj) {
              // Resize only if the image is new (has originFileObj)
              Resizer.imageFileResizer(
                file.originFileObj,
                512, // Width
                512, // Height
                "JPEG", // Format
                100, // Quality
                0, // Rotation
                (uri) => {
                  const blob = dataURLtoBlob(uri);
                  resolve({
                    ...file,
                    originFileObj: new File([blob], file.name, {
                      type: file.type,
                    }),
                  });
                },
                "base64"
              );
            } else {
              // If it's an existing image, just resolve without resizing
              resolve(file);
            }
          })
      );

      const resizedImages = await Promise.all(resizedImagesPromises);

      // Update the imageList with unique images, ensuring no duplicates
      setImageList((prevImageList) => {
        const mergedList = [...prevImageList, ...resizedImages];
        const uniqueImages = mergedList.filter(
          (image, index, self) =>
            index ===
            self.findIndex(
              (t) => t.name === image.name && (!t.url || t.url === image.url)
            )
        );
        return uniqueImages;
      });
    } else if (key === "videos") {
      const validVideo = fileList.filter((file) => file.size / 1048576 <= 10);
      if (validVideo.length !== fileList.length) {
        message.error("Video size cannot exceed 10 MB.");
      }
      setVideoList(validVideo.slice(0, 1));
    }
  };
  const handleRemove = (file) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this image?"
    );
    if (confirmDelete) {
      setImageList((prevList) =>
        prevList.filter((item) => item.uid !== file.uid)
      );
      message.success("Image deleted successfully.");
    } else {
      // Prevent the removal if canceled
      return false;
    }
  };
  const handleUpload = async () => {
    if (imageList.length === 0 && videoList.length === 0) {
      message.error("Please select at least one image or video.");
      return;
    }
    setIsLoading(true);
    try {
      const formData = new FormData();

      // Append new images (files) to 'images' key
      imageList.forEach((image) => {
        if (image.originFileObj) {
          // New images
          formData.append("images", image.originFileObj);
        }
      });

      // Collect all previous image URLs
      const previousImageUrls = imageList
        .filter((image) => !image.originFileObj && image.url) // Only images without originFileObj (old images)
        .map((image) => image.url) // Extract the URLs
        .join(","); // Join URLs with a comma

      // Append the concatenated URLs string to 'imageUrls' key
      if (previousImageUrls) {
        formData.append("imageUrls", previousImageUrls);
      }

      // If you have video handling
      videoList.forEach((video) => {
        formData.append("videos", video.originFileObj);
      });

      // Log image list to check data structure
      console.log("****");
      console.log(imageList);

      // Perform the PUT request
      const response = await fetch(`/api/utility/dish/${dishId}`, {
        method: "PUT",
        headers: {
          authorization: "authorization-text",
        },
        body: formData,
      });

      if (response.ok) {
        message.success("Files uploaded successfully");
        dishListFetchData(currentPage);
        handleCloseUpload();
        setImageList([]);
        setVideoList([]);
      } else {
        message.error("File upload failed");
        dishListFetchData(currentPage);
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      message.error("File upload failed");
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setDishId(null);
  };
  const handleCloseUpload = () => {
    setUploadImageModal(false);
    setDishId(null);
    setImageList([]);
    setVideoList([]);
  };
  const handleShow = (itemId: string | null) => {
    setShowModal(true);
    setDishId(itemId!);
  };
  const handleDeleteClose = () => {
    setShowDeleteModal(false);
    setDishId(null);
    setFullError("");
  };
  const handleDeleteShow = (itemId: string) => {
    setShowDeleteModal(true);
    setDishId(itemId!);
  };
  const handleUploadShow = (itemId: string) => {
    setImageList([]);
    setVideoList([]);
    setUploadImageModal(true);
    setDishId(itemId!);
  };
  const handleUploadShowTemp = () => {
    setUploadImageModal(true);
    handleRegisterClose();
  };
  const handleRegisterClose = () => {
    dishListFetchData(currentPage);
    setShowRegisterModal(false);
  };
  const handleRegisterShow = () => {
    setShowRegisterModal(true);
  };
  const handleUpdateClose = () => {
    setShowUpdateModal(false);
    setDishId(null);
  };
  const handleUpdateShow = (itemId: string) => {
    setShowUpdateModal(true);
    setDishId(itemId!);
  };

  const inActiveState = () => {
    message.error("Dish is in active state. can't edit");
    // toast.error("Dish is in active state. can't edit");
    // console.log("Dish is in active State. can't edit")
  };

  const handleStatusUpdateClose = () => setShowStatusUpdateModal(false);
  const handleStatusUpdateShow = (itemId: string) => {
    setShowStatusUpdateModal(true);
    setDishId(itemId!);
  };
  const updateStatus = async () => {
    setLoading(true);
    console.log(" update status ");
    try {
      await api
        .put(
          "/api/restaurant/dish/status/" + dishId + "?statusCode=" + dishStatus
        )
        .then(function (response) {
          // toast.success("Status updated Successfully!");
          message.success("Status updated Successfully!");

          setLoading(false);
          setDishStatus("");
          console.log(response.data);
          handleStatusUpdateClose();
          setDishId(null);
          dishListFetchData(currentPage);
          return response.data;
        })
        .catch(function (error) {
          console.log("Show error notification!");
          message.error("Error in API call!");
          setLoading(false);
          console.log(error);
          return Promise.reject(error);
        });
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  const dishListFetchData = async (page: number) => {
    try {
      console.log("fetch detailss");
      // setLoading(true);
      const response = await api.get(
        `/api/restaurant/dish/?page=${page}&limit=${ITEMS_PER_PAGE}&searchText=` +
          search
      );
      const dishData = response.data;
      const totalCount = dishData.data.totalCount;
      // console.log("dishData", dishData);
      setDishData(dishData.data.dish || []);
      setTotalPages(Math.ceil(totalCount / ITEMS_PER_PAGE));
      // setError(
      //   dishData.length > 0
      //     ? null
      //     : "No results found for the given search text."
      // );
    } catch (error: any) {
      console.error("Error fetching data:", error);
      // setError("An unexpected error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dishListFetchData(currentPage);
  }, [currentPage, search]);

  const dishDetailFetchData = async (id: string | null) => {
    try {
      setLoading(true);
      if (id !== null) {
        const response = await api.get(`/api/restaurant/dish/${id}`);
        const dishDetailData = response.data;
        setDishDetailData(dishDetailData.data);
        console.log("dishDetailData", dishDetailData.data.imageAttachement);
        const existingImages = dishDetailData.data.imageAttachement.map(
          (image: any) => ({
            uid: image._id, // Use _id as uid
            name: image.url.split("/").pop(), // Extract the file name from the URL
            status: "done", // Mark as already uploaded
            url: image.url, // Use the URL from dishDetailData
            originFileObj: null, // Not needed for already uploaded images
          })
        );

        // Now set the imageList after data is fetched
        setImageList(existingImages);
      }
    } catch (error: any) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dishDetailFetchData(dishId);
  }, [dishId]);
  const [fullError, setFullError] = useState("");
  const handleDelete = async () => {
    try {
      if (dishId !== null) {
        setLoading(true);
        const response = await api.delete(`/api/restaurant/dish/${dishId}`);
        setDishId(null);
        await dishListFetchData(currentPage);
        handleDeleteClose();
        console.log("in if block");
        message.success(response.data.message);
      }
      console.log("Outside if block");
    } catch (error) {
      console.error("Error deleting restaurant:", error);
      message.error(error.response.data.message.substring(0, 75));
      const errorMessage = error.response.data.message;
      setFullError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]:
        name === "productionCost" ||
        name === "preparationTime" ||
        name === "servingSize"
          ? parseInt(value, 10)
          : value,
    });
  };

  const handleIngredientChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index: number
  ) => {
    const { name, value } = e.target;

    const updatedIngredients = formData.ingrediants.map((ingredient: any, i) =>
      i === index
        ? {
            ...ingredient,
            [name]: name === "quantity" ? parseInt(value, 10) : value,
          }
        : ingredient
    );

    setFormData({
      ...formData,
      ingrediants: updatedIngredients,
    });
  };

  const handleAddIngredient = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      ingrediants: [
        ...prevFormData.ingrediants,
        { name: "", description: "", quantity: 0, unit: "" },
      ],
    }));
  };

  const handleRemoveTag = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      categoryTag: prevFormData.categoryTag.slice(0, -1),
    }));
  };

  const categoryTagChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index: number
  ): void => {
    const { value } = e.target;

    const updatedCategoryTag = formData.categoryTag.map((tag, i) =>
      i === index ? value : tag
    );

    setFormData({
      ...formData,
      categoryTag: updatedCategoryTag,
    });
  };

  const handleAddCategoryTag = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      categoryTag: [...prevFormData.categoryTag, ""],
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    console.log("edit submit called");
    event.preventDefault();

    const updateData = {
      name: formData.name,
      description: formData.description,
      productionCost: formData.productionCost,
      preparationTime: formData.preparationTime,
      servingSize: formData.servingSize,
      categoryTag: formData.categoryTag,
      ingrediants: formData.ingrediants.map((ingredient: any) => ({
        name: ingredient.name,
        description: ingredient.description,
        quantity: ingredient.quantity,
        unit:
          null == ingredient.unit || "" === ingredient.unit
            ? "mg"
            : ingredient.unit,
      })),
    };

    try {
      setLoading(true);

      const response = await api.put(
        `/api/restaurant/dish/${dishId}`,
        updateData
      );

      console.log("Updated dish response", response);
      message.success(response.data.message);

      handleUpdateClose();
      dishListFetchData(currentPage);
    } catch (error: any) {
      console.error("There was an error during update!", error.response.data);

      if (error.response) {
        message.error(error.response.data.message);
        setTimeout(function () {
          handleUpdateClose();
        }, 10000);
      } else {
        message.error("Please fill out all required fields.");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const loggedInUserData = localStorage.getItem("loggedInUser");
    if (loggedInUserData) {
      const parsedData = JSON.parse(loggedInUserData);
      setDefaultCurrency(parsedData.restaurantdefaultCurrencyCode);
    }
  }, []);

  const getLocalFormattedDate = (date: string | undefined) => {
    if (date) {
      var localDate = new Date(date);

      const year = localDate.getFullYear();
      const month = String(localDate.getMonth() + 1).padStart(2, "0"); // Adding 1 because getMonth returns 0-based index
      const day = String(localDate.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    }

    return "";
  };

  const handleRemoveIngredient = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      ingrediants: prevFormData.ingrediants.slice(0, -1),
    }));
  };

  const SetMenuID = (menuId: string) => {
    if (menuId !== null || 0) {
      setUploadImageModal(true);
      setDishId(menuId!);

      console.log("Menu id");
      console.log(menuId);
    }
  };

  return (
    <div>
      <div>
        <div className={`body-area${showNav ? " body-pd" : ""}`}>
          <SideBarHeader showNav={showNav} setShowNav={setShowNav} />
          <div className={`l-navbar${showNav ? " showSideNav" : ""}`}>
            <nav className="sideNav">
              <SideMenu />
            </nav>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="pt-4 pb-4">
              <section className="main shadow-sm">
                <div className="row mb-4">
                  <div className="col">
                    {/* <h4>Dish List</h4> */}
                    <h4> {SidebarSection.DISH_LIST}</h4>
                  </div>
                  <div className="col text-end d-flex">
                    <input
                      placeholder="Search by Dish Name..."
                      value={search}
                      onChange={(e: any) => handleSearchChange(e)}
                      type="text"
                      name="search"
                      className="form-control search"
                      id="Search"
                      aria-describedby="emailHelp"
                    />
                    <button
                      type="button"
                      className="btn addbtn"
                      // onClick={prepareAddDish}
                      onClick={handleRegisterShow}
                      // onClick={() => {
                      //   viewDishDetails("1", "register");
                      // }}
                    >
                      <i className="bi bi-clipboard-plus"></i>
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr className="table-active">
                          <th scope="col">#</th>
                          <th scope="col">NAME</th>
                          <th scope="col">PRICE ({defaultCurrency})</th>
                          <th scope="col">SMALL DESCRIPTION</th>
                          <th scope="col">CREATED DATE</th>
                          <th scope="col">LAST UPDATED DATE</th>
                          <th scope="col">CATEGORY TAG</th>
                          <th scope="col" style={{ padding: "0 40px" }}>
                            STATUS
                          </th>
                          <th scope="col">ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dishData.map((item, index) => (
                          <tr key={item._id}>
                            <td>{(currentPage - 1) * 10 + index + 1}</td>
                            <td>{item.name}</td>
                            <td>{item.productionCost}</td>
                            <td>{item.description}</td>
                            <td>{getLocalFormattedDate(item.createdAt)}</td>
                            <td>{getLocalFormattedDate(item.updatedAt)}</td>
                            <td>
                              {item.categoryTag.map((e) => {
                                return <p>{e}</p>;
                              })}
                            </td>

                            <td>
                              {item.status === "active" ? (
                                <span className="badge bg-success rounded-pill">
                                  Active
                                </span>
                              ) : (
                                <span className="badge bg-error rounded-pill"></span>
                              )}
                              {item.status === "suspended" ? (
                                <span className="badge bg-error rounded-pill">
                                  Suspended
                                </span>
                              ) : (
                                <span className="badge bg-error rounded-pill"></span>
                              )}
                              {item.status === "creating" ? (
                                <span className="badge bg-warning rounded-pill">
                                  Creating
                                </span>
                              ) : (
                                <span className="badge bg-error rounded-pill"></span>
                              )}
                              {item.status === "waiting_for_approval" ? (
                                <span className="badge bg-warning rounded-pill">
                                  waitingForApproval
                                </span>
                              ) : (
                                <span className="badge bg-error rounded-pill"></span>
                              )}
                              &nbsp;
                              <button
                                className="btn-action rounded-circle act-btn"
                                onClick={() => handleStatusUpdateShow(item._id)}
                              >
                                <i className="bi bi-pencil-square"></i>
                              </button>
                            </td>

                            <td className="p-1 d-flex">
                              <button
                                className="btn-action rounded-circle act-btn m-1"
                                onClick={() => {
                                  // viewDishDetails(item._id, "view");
                                  handleShow(item._id);
                                }}
                              >
                                <i className="bi bi-eye"></i>
                              </button>{" "}
                              &nbsp;
                              {item.status === "active" ? (
                                <button
                                  className="btn-action rounded-circle act-btn m-1"
                                  onClick={() => {
                                    // viewDishDetails(item._id, "edit");
                                    inActiveState();
                                  }}
                                >
                                  <i className="bi bi-pencil-square"></i>
                                </button>
                              ) : (
                                <button
                                  className="btn-action rounded-circle act-btn m-1"
                                  onClick={() => {
                                    // viewDishDetails(item._id, "edit");
                                    handleUpdateShow(item._id);
                                  }}
                                >
                                  <i className="bi bi-pencil-square"></i>
                                </button>
                              )}
                              <button
                                className="btn-action rounded-circle act-btn m-1"
                                onClick={() => {
                                  // viewDishDetails(item._id, "delete");
                                  handleDeleteShow(item._id);
                                }}
                              >
                                <i className="bi bi-trash"></i>
                              </button>
                              <button
                                className="btn-action rounded-circle act-btn m-1"
                                onClick={() => {
                                  // viewDishDetails(item._id, "delete");
                                  handleUploadShow(item._id);
                                }}
                              >
                                <i className="bi bi-upload"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>
            </div>
          )}
        </div>
        <div className=" d-flex  justify-content-end pe-md-5">
          {!loading && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>
      </div>
      {showModal && (
        <div
          className="modal"
          tabIndex={-1}
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Dish Details
                </h1>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleClose}
                ></button>
              </div>
              <>
                <DishDetailView
                  dishDetailData={dishDetailData}
                  handleClose={handleClose}
                  defaultCurrency={defaultCurrency}
                />
              </>
            </div>
          </div>
        </div>
      )}

      {showDeleteModal && (
        <div
          className="modal"
          tabIndex={-1}
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Delete Dish?
                </h1>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleDeleteClose}
                ></button>
              </div>
              <div className="delete-text">
                {" "}
                Are you sure you want to delete?
                <br />
                <br />
                {fullError && (
                  <span className="text-danger">* {fullError}</span>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleDeleteClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={handleDelete}
                  className="btn btn-primary"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showStatusUpdateModal && (
        <div
          className="modal"
          tabIndex={-1}
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Update status?
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleStatusUpdateClose}
                ></button>
              </div>
              <div className="delete-text">
                <div className="mb-12 col-md-12">
                  <label className="form-label">
                    Select status <span className="text-danger"> *</span>
                  </label>
                  <div>
                    <select
                      className="form-control down-arrow"
                      value={dishStatus}
                      onChange={(e) => setDishStatus(e.target.value)}
                    >
                      <option value="" disabled>
                        Select Status
                      </option>
                      <option value="creating">Creating</option>

                      <option value="active">Active</option>

                      <option value="suspended">Suspended</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleStatusUpdateClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={updateStatus}
                  className="btn btn-primary"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showRegisterModal && (
        <div
          className="modal"
          tabIndex={-1}
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Dish Creation
                </h1>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleRegisterClose}
                ></button>
              </div>
              <>
                <DishRegister
                  handleRegisterClose={handleRegisterClose}
                  SetMenuID={SetMenuID}
                />
              </>
            </div>
          </div>
        </div>
      )}
      {showUpdateModal && (
        <div
          className="modal"
          tabIndex={-1}
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          {loading && <Loader />}
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Dish Update
                </h1>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleUpdateClose}
                ></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className="row">
                    <div className="col">
                      <div className="mb-3">
                        <label className="form-label">
                          Dish Name<span className="text-danger"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={formData.name}
                          onChange={handleInputChange}
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-label">
                          Average Preparation Time (in minutes)
                          {/* <span className="text-danger"> *</span> */}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="preparationTime"
                          value={formData.preparationTime}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="col">
                      <div className="mb-3">
                        <label className="form-label">
                          {" "}
                          Base Production Cost ({defaultCurrency})
                          {/* <span className="text-danger"> *</span> */}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="productionCost"
                          value={formData.productionCost}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">
                          Number of people who can consume the dish{" "}
                          {/* <span className="text-danger"> *</span> */}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="servingSize"
                          value={formData.servingSize}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="mb-3">
                        <label className="form-label">
                          {" "}
                          It would be great if you add a description about the
                          dish <span className="text-danger"> *</span>
                        </label>
                        <textarea
                          className="form-control"
                          name="description"
                          value={formData.description}
                          onChange={handleInputChange}
                          rows={5}
                          maxLength={200}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <h5>Ingredients</h5>
                  <div className="row">
                    <div className="col">
                      <table className="table table-bordered">
                        <tbody>
                          {formData.ingrediants.map(
                            (ingredient: any, index: any) => (
                              <tr key={index}>
                                <td>
                                  <label>
                                    Ingredient Name
                                    <span className="text-danger"> *</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Ingredient Name"
                                    name="name"
                                    value={ingredient.name}
                                    onChange={(e) =>
                                      handleIngredientChange(e, index)
                                    }
                                  />
                                </td>
                                <td>
                                  <label>Quantity</label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Qty"
                                    name={`quantity`}
                                    value={ingredient.quantity}
                                    onChange={(e) =>
                                      handleIngredientChange(e, index)
                                    }
                                  />
                                </td>
                                <td>
                                  <label>Description</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="description"
                                    name={`description`}
                                    value={ingredient.description}
                                    onChange={(e) =>
                                      handleIngredientChange(e, index)
                                    }
                                  />
                                </td>
                                <td>
                                  <div className="mb-3">
                                    <label>Unit</label>
                                    <select
                                      className="form-control down-arrow"
                                      name={`unit`}
                                      id={`unit`}
                                      value={ingredient.unit}
                                      onChange={(e) => {
                                        handleIngredientChange(e, index);
                                        // console.log("unit", e.target.value);
                                      }}
                                    >
                                      <option value="mg">Milligram</option>
                                      <option value="ml">Millilitre</option>
                                      <option value="nos">Numbers</option>
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  {index === 0 ? (
                                    <div></div>
                                  ) : (
                                    <button
                                      type="button"
                                      className="btn btn-primary rounded-circle act-btn"
                                      style={{
                                        background: "red",
                                        borderColor: "red",
                                      }}
                                      onClick={handleRemoveIngredient}
                                    >
                                      <i className="bi bi-file-minus"></i>
                                    </button>
                                  )}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="col-1">
                      <button
                        type="button"
                        className="btn btn-primary rounded-circle act-btn"
                        onClick={handleAddIngredient}
                      >
                        <i className="bi bi-file-plus"></i>
                      </button>
                    </div>
                  </div>
                  <h5>Dish Tagging(Ex.Indian, Delicious, Healthy etc.)</h5>
                  <div className="row">
                    <div className="col">
                      <table className="table table-bordered">
                        <tbody>
                          {/* {renderInputs()} */}
                          {formData.categoryTag.map(
                            (categoryTag: any, index: any) => (
                              <tr key={index}>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Tag Name"
                                    name={`categoryTag`}
                                    value={categoryTag}
                                    onChange={(e) =>
                                      categoryTagChange(e, index)
                                    }
                                  />
                                </td>
                                <td>
                                  {index === 0 ? (
                                    <div></div>
                                  ) : (
                                    <button
                                      className="btn btn-primary rounded-circle act-btn"
                                      style={{
                                        background: "red",
                                        borderColor: "red",
                                      }}
                                      onClick={handleRemoveTag}
                                    >
                                      <i className="bi bi-file-minus"></i>
                                    </button>
                                  )}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="col-1">
                      <button
                        type="button"
                        className="btn btn-primary rounded-circle act-btn "
                        onClick={handleAddCategoryTag}
                      >
                        <i className="bi bi-file-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={handleUpdateClose}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Save & Next <i className="bi bi-caret-right-fill"></i>
                  </button>
                  <ToastContainer />
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {uploadImageModal && (
        <div
          className="modal"
          tabIndex={-1}
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Upload Dish images
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleCloseUpload}
                ></button>
              </div>
              <section className="main shadow-sm">
                {isLoading ? (
                  <div className="text-center my-4">
                    <Spin tip="Uploading... Please wait" />
                  </div>
                ) : (
                  <>
                    <div className="row ">
                      <div>
                        <Upload
                          multiple
                          listType="picture-card" // This will display images as thumbnails
                          beforeUpload={() => false} // Disable auto upload
                          fileList={imageList}
                          onChange={(info) => handleFileChange("images", info)} // Handle file change
                          onRemove={handleRemove} // Handle remove action
                        >
                          <Button
                            className="text-start"
                            style={{ width: "310px", height: "103px" }}
                            icon={<UploadOutlined />}
                          >
                            Dish Image

                            
                          </Button>
                        </Upload>
                        <span>
                          {" "}
                          (*Please select maximum 3 images and dimensions should
                          be 512*512 max.)
                        </span>
                      </div>
                      <div className="col-lg-6 col-md-6 text-center">
                        <Upload
                          listType="picture-card"
                          beforeUpload={() => false}
                          fileList={videoList}
                          onChange={(info) => handleFileChange("videos", info)}
                        >

                          <Button
                            className="text-start"
                            style={{ width: "310px", height: "103px" }}
                            icon={<UploadOutlined />}
                          >
                            Dish video
                          </Button>
                          
                        </Upload>
                        <span> (*Please select only one video)</span>
                      </div>
                    </div>
                    <div className="modal-footer">
                      {/* <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                        onClick={handleCloseUpload}
                      >
                        Close
                      </button> */}
                      <button
                        onClick={handleUpload}
                        type="submit"
                        className="btn btn-primary"
                      >
                        Save
                      </button>
                      <ToastContainer />
                    </div>
                  </>
                )}
              </section>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}

export default Dish;
