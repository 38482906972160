import React, { useEffect, useState } from "react";
import { DishDetailsInterface } from "./interface";
import api from "../../api";

interface DishDetailViewProps {
  dishDetailData: DishDetailsInterface | null;
  handleClose: () => void;
  restaurantId: string;
  defaultCurrency: string;
}
// const getLocalFormattedDate = (date: string | undefined) => {
//   if (date) {
//     var localDate = new Date(date);

//     const year = localDate.getFullYear();
//     const month = String(localDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth returns 0-based index
//     const day = String(localDate.getDate()).padStart(2, '0');
//     const formattedDate = `${year}-${month}-${day}`;
//     return formattedDate;
//   }


//   return "";
// }

const DishDetailView: React.FC<DishDetailViewProps> = ({
  dishDetailData,
  handleClose,restaurantId,defaultCurrency
}) => {
  const [branchNames, setBranchNames] = useState({});
  useEffect(() => {
    const fetchBranchNames = async () => {
      if (!dishDetailData || !Array.isArray(dishDetailData.assignedBranch)) {
        console.error("Invalid dishDetailData:", dishDetailData);
        return;
      }

      const branchIds = dishDetailData.assignedBranch.map(
        (branch) => branch.branchId
      );
      const names = {};

      for (const branchId of branchIds) {
        try {
          const response = await api.get(
            `/api/identity/restaurant-branch/${branchId}?restaurantId=` +
              restaurantId
          );
          names[branchId] = response.data.data.name;
        } catch (error) {
          console.error(
            `Failed to fetch branch data for branchId: ${branchId}`,
            error
          );
        }
      }

      setBranchNames(names);
    };

    fetchBranchNames();
  }, [dishDetailData]);
  const formatDateTime = (dateString: string) => {
    const date = new Date(dateString);
  
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: userTimeZone, // Set to the user's local time zone
    };
  
    const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(date);
  
    // Custom formatting logic to transform "DD/MM/YYYY, HH:mm AM/PM" into "DD/MM/YYYY Hour:min AM/PM"
    const [datePart, timePart] = formattedDate.split(", ");
    const [day, month, year] = datePart.split("/");
    const [hourMinute, period] = timePart.split(" ");
  
    return `${day}/${month}/${year} ${hourMinute} ${period}`;
  }; 
  const checkIfExpired = (endDate: string) => {
    const now = new Date();
    return new Date(endDate) < now;
  };
  return (
    <>
      {" "}
      <form>
        <div className="modal-body">
          <table className="table table-bordered view-table">
            <tbody>
              <tr>
                <th>OFFER NAME</th>
                <td>{dishDetailData?.name}</td>
                <th>Description</th>
                <td>{dishDetailData?.description}</td>
                
              </tr>
              <tr>
                <th>DISH NAME</th>
                <td>{dishDetailData?.dish.name}</td>
                <th>QUANTITY</th>
                <td>{dishDetailData?.dish.selectedQuantity}</td>
                
              </tr>
              <tr>
                <th>TOTAL PRICE ({defaultCurrency})</th>
                <td>{dishDetailData?.totalPrice}</td>
                <th>OFFER PRICE ({defaultCurrency})</th>
                <td>{dishDetailData?.offerPrice}</td>
              
              </tr>
              
              
              <tr>
                <th>ACTIVE</th>
                <td>{dishDetailData?.isActive ? "Yes" : "No"}</td>
                <th>STATUS</th>
                {/* <td>
                  {dishDetailData?.status}</td> */}
                <td>{dishDetailData?.status === 'active' ? (
                  <span className="badge bg-success rounded-pill">
                    Active
                  </span>
                ) : (
                  <span className="badge bg-error rounded-pill">

                  </span>
                )}

                  {dishDetailData?.status === 'suspended' ? (
                    <span className="badge bg-error rounded-pill">
                      Suspended
                    </span>
                  ) : (
                    <span className="badge bg-error rounded-pill">

                    </span>
                  )}

                  {dishDetailData?.status === 'creating' ? (
                    <span className="badge bg-warning rounded-pill">

                      Creating
                    </span>
                  ) : (
                    <span className="badge bg-error rounded-pill">

                    </span>
                  )}</td>

              </tr>
              <tr>
                <th>
                  ASSIGNED <br />
                  BRANCHES
                </th>
                <td colSpan={3}>
                  <table className="sub-table">
                    <tbody>
                      <tr>
                        <th>BRANCH</th>
                        <th>START DATE & TIME</th>
                        <th>END DATE & TIME</th>
                      </tr>
                      {dishDetailData?.assignedBranch.map((assignedBranch) => (
                        <tr key={assignedBranch.branchId}>
                          <td>
                            {branchNames[assignedBranch.branchId] ||
                              assignedBranch.branchId}
                          </td>
                          <td>{formatDateTime(assignedBranch.startDate)}</td>
                          <td>{formatDateTime(assignedBranch.endDate)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <th>DISH IMAGE</th>
                
                <td>
                  <div style={{ display: 'flex', gap: '10px' }}>
                    {dishDetailData?.dish.imageAttachement?.map((image, index) => (
                      <img
                        key={image._id}
                        src={image.url ? image.url : '/img/menu/menu-item-2.png'}
                        className="menu-img1 img-fluid"
                        alt={`Dish Img ${index + 1}`}
                        width={130}
                        height={110}
                      />
                    ))}
                  </div>
                </td>

                <td>
                {dishDetailData?.assignedBranch?.[0]?.endDate && checkIfExpired(dishDetailData.assignedBranch[0].endDate) && (
                  <h3 style={{color:"orange"}}>Offer Expired</h3>

                )}
              </td>
              </tr>
              
              

            </tbody>
          </table>
        </div>
        
      </form>
    </>
  );
};

export default DishDetailView;
