import React, { useEffect, useState } from "react";
import reservation from "./img/reservation.jpg";
import { StringFormItem } from "../inputs/StringFormItem";

import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import {
  Form,
  Button,
  message,
  Upload,
  Input,
  UploadProps,
  // TimePicker,
  // Steps,
  Checkbox,
  Modal

} from "antd";
import { Link } from "react-router-dom";
import {
  UploadOutlined,
  // PlusOutlined,
  // DeleteOutlined,
} from "@ant-design/icons";
// import dayjs from "dayjs";
import "./registration.scss";
import BranchData from "./branchData";
import GoogleMapComp from "./googleMap"
import api from "../../api";
import { RegistrationSection } from "../../constants/constant";
import Loader from "../Loader";
import { ToastContainer, toast } from "react-toastify";
import SearchableDropdown from "./SearchableDropdown";
import thumbsUpGif from './img/ThumbsUp.gif';
// import Select from 'react-select';
export interface currencyInterface {
  name: string;
  _id: string;
  code: string;
  symbol: string;
}
interface CurrencyOption {
  value: string;
  label: string;
}
export default function RegistrationRestaurants() {
  // const navigate = useNavigate();
  // const [file, setFile] = useState<File | null>(null);
  const [form] = Form.useForm();
  const [activeFormNo, setActiveFromNo] = useState(1);
  // const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  // const [currencyData, setCurrencyData] = useState<currencyInterface[]>([]);
  // const [selectedCurrencyOption, setSelectedCurrencyOption] = useState<CurrencyOption | null>(null);
  // const Step = Steps.Step;
  const [currencyOptions, setCurrencyOptions] = useState<CurrencyOption[]>();

  const handleChange = (selectedOption: any) => {
    // setSelectedCurrencyOption(selectedOption.value);
    form.setFieldsValue({ defaultCurrencyId: selectedOption.value });
  };
  const [showTermsModal, setShowTermsModal] = useState(false);

  

  const props: UploadProps = {
    name: "file",
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const cussineData = [
    { name: "No Barley" },
    { name: "No Dairy" },
    { name: "No Fish" },
    { name: "No Eggs" },
    { name: "No Glutten" },
    { name: "No Seeds " },
    { name: "No Soya" },
    { name: "No Mustrads" },
    { name: "No dummy" },
    { name: "No data" },
  ];

  const currencyFetchData = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/api/identity/currency/`);
      // console.log("currency response", response.data.data);

      setCurrencyOptions(response.data.data.map((item: { _id: any; name: any; }) => ({
        value: item._id,
        label: item.name
      })));

      // setCurrencyData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      // setError("An unexpected error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    currencyFetchData();
  }, []);

  // if (loading) {
  //   <Loader />;
  // }
  useEffect(() => {
    setTimeout(() => setLoading(false), 3300);
  }, []);
  // if (loading) {
  //   return <Loader />;
  // }
  const handleLocationSelect = (locationName) => {
    form.setFieldsValue({ place: locationName });
  };
  useEffect(() => {
    // Scroll to top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  
  const [cuisineTags, setCuisineTags] = useState([""]);

  const addCuisineTag = () => {
    setCuisineTags([...cuisineTags, ""]);
  };

  const removeCuisineTag = (index) => {
    const updatedTags = cuisineTags.filter((_, i) => i !== index);
    setCuisineTags(updatedTags);
  };

  const handleCuisineTagChange = (index, value) => {
    const updatedTags = [...cuisineTags];
    updatedTags[index] = value;
    setCuisineTags(updatedTags);
  };

  const onFinish = async () => {
    try {
      const values = await form.validateFields();
      delete values.acceptTerms;
    values.cuisineTag = cuisineTags; // Pass cuisineTags array only

    const response = await api.post("/api/identity/restaurant/", values);
    console.log("register response", response.data);
    toast.success(response.data.message);
    setTimeout(() => {
      setActiveFromNo(5);
    }, 2000);
    } catch (error: any) {
      // Explicitly type 'error' as 'any'
      console.error("There was an error!", error);

      if (error.response) {
        // If the error has a response, extract the error message from the response data
        toast.error(error.response.data.message);
      } else {
        // If there is no response, display a generic error message
        toast.error("Please fill out all required fields.");
      }
    }
  };
  
  return (
    <div className="min-vh-100">
      {loading && <Loader />}

      <>
        {/* ======= Book A Table Section ======= */}
        <section id="book-a-table" className="book-a-table mt-5">
          <div className="container" data-aos="fade-up">
            <div className="section-header">
              {/* <h2>Book A Table</h2> */}
              <p>
                {RegistrationSection.REGISTER}{" "}
                <span>{RegistrationSection.WITH_US}</span>{" "}
                {RegistrationSection.TO_GET_LISTED}
              </p>
            </div>
            <div className="row g-0">
              <div
                className="col-lg-4 reservation-img  reservation-form-bg"
                style={{ backgroundImage: reservation }}
                data-aos="zoom-out"
                data-aos-delay={200}
              >
                <img
                  src={reservation}
                  width={372}
                  alt=""
                />{" "}
              </div>
              <div className="col-lg-8  reservation-form-bg">
                {/* <div className="p-3">
                 <Steps>
                    <Step
                      status={activeFormNo == 1 ? "process" : "finish"}
                      title="Basic Details"
                    />
                    <Step
                      status={
                        activeFormNo == 2
                          ? "process"
                          : activeFormNo == 3
                          ? "finish"
                          : "wait"
                      }
                      title="Branch Details"
                    />
                    <Step
                      status={
                        activeFormNo == 3
                          ? "process"
                          : activeFormNo == 3
                          ? "finish"
                          : "wait"
                      }
                      title="Document Upload"
                    />
                    <Step
                      status={
                        activeFormNo == 4
                          ? "process"
                          : activeFormNo == 3
                          ? "finish"
                          : "wait"
                      }
                      title="Cuisine"
                    />

                    <Step
                      status={activeFormNo == 5 ? "process" : "wait"}
                    />
                  </Steps> 
                </div>*/}

                <Form
                  action="forms/book-a-table.php"
                  method="post"
                  role="form"
                  className="php-email-form"
                  data-aos="fade-up"
                  data-aos-delay={100}
                  form={form}
                  layout="vertical"
                  onFinish={onFinish}
                >
                  {activeFormNo === 1 && (
                    <>
                      <div className="row">
                        <div className="col-lg-4 col-md-6">
                          <StringFormItem
                            label="Restaurant Name"
                            name="name"
                            disabled={""}
                            placeholder="Name of the restaurant *"
                            rules={[
                              {
                                required: true,
                                message: "Please enter restaurant name",
                              },
                            ]}
                          />
                          <div className="validate" />
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <StringFormItem
                            label="Brand Name"
                            name="brandName"
                            disabled={""}
                            placeholder="Name of the brand"

                          />

                        </div>
                        <div className="col-lg-4 col-md-6">
                          <StringFormItem
                            label="Tagline"
                            name="tagline"
                            disabled={""}
                            placeholder="Tagline"

                          />

                        </div>
                        <div className="col-lg-4 col-md-6">
                          <StringFormItem
                            label="Contact Person Name"
                            name="contactPersonName"
                            disabled={""}
                            placeholder="Contact Person Name *"
                            rules={[
                              {
                                required: true,
                                message: "Please enter contact person",
                              },
                              {
                                pattern: /^[a-zA-Z\s]+$/,
                                message: "Name can only contain letters and spaces",
                              }

                            ]}
                          />
                          <div className="validate" />
                        </div>

                        <div className="col-lg-4 col-md-6">
                          <StringFormItem
                            label="  Contact Person Mobile Number"
                            name="contactPersonContactNumber"
                            disabled={""}
                            placeholder=" +971XXXXXXXXXX"
                            rules={[
                              {
                                required: true,
                                message: "Please enter Contact Number",
                              },
                            ]}
                          />
                          <div className="validate" />
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <StringFormItem
                            label="Restaurant Email ID"
                            name="email"
                            disabled={""}
                            placeholder="Restaurant Email ID *"
                            rules={[
                              {
                                required: true,
                                message: "Please input your E-mail!",
                              },
                              {
                                pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                message: "Email is not valid",
                              }
                            ]}
                          />
                          <div className="validate" />
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <StringFormItem
                            label="Owner’s Name"
                            name="ownerName"
                            disabled={""}
                            placeholder="Owner’s Name"
                            rules={[
                              {
                                pattern: /^[a-zA-Z\s]+$/,
                                message: "Name can only contain letters and spaces",
                              }
                            ]}
                          />


                        </div>
                        <div className="col-lg-4 col-md-6">
                          <StringFormItem
                            label="Owner's Phone number"
                            name="ownerContactNumber"
                            disabled={""}
                            placeholder="+971XXXXXXXXXX"

                          />

                        </div>
                        <div className="col-lg-4 col-md-6">
                          <StringFormItem
                            label="Owner’s Email ID"
                            name="ownerEmail"
                            disabled={""}
                            placeholder="Owner’s Email ID "
                            rules={[
                              {
                                pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                message: "Email is not valid",
                              }
                            ]}
                          />
                          <div className="validate" />
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <StringFormItem
                            label="Restaurant Contact Number"
                            name="contactNumber"
                            disabled={""}
                            placeholder="+971XXXXXXXXXX"

                          />

                        </div>
                        <div className="col-lg-4 col-md-6">
                          <StringFormItem
                            label="Restaurant Mobile Number"
                            name="mobileNumber"
                            disabled={""}
                            placeholder="+971XXXXXXXXXX"

                          />

                        </div>
                        <div className="col-lg-4 col-md-6">
                          <StringFormItem
                            label="Contact person email ID"
                            name="contactPersonEmail"
                            disabled={""}
                            placeholder="Contact person email ID "
                            rules={[
                              {
                                pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                message: "Email is not valid",
                              }
                            ]}
                          />
                          <div className="validate" />
                        </div>

                        
                      </div>

                      <div className="row">
                        
                        
                        <div className="col-lg-4 col-md-6">
                        <Form.Item label="Cuisine Tags"
                        name="cuisineTag"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Cuisine Tags",
                          },]}
                        >
                            {cuisineTags.map((tag, index) => (
                              <div key={index} style={{ display: "flex", marginBottom: 8 }}>
                                <Input
                                  value={tag}
                                  onChange={(e) => handleCuisineTagChange(index, e.target.value)}
                                  placeholder="Enter a cuisine tag"
                                  style={{ marginRight: 8 }}
                                />
                                {cuisineTags.length > 1 && (
                                  <MinusCircleOutlined
                                    onClick={() => removeCuisineTag(index)}
                                    style={{ color: "red" }}
                                  />
                                )}
                                &nbsp;
                                {index === 0 && (
                                <PlusCircleOutlined
                                  onClick={addCuisineTag}
                                  style={{ color: "red" }}
                                />
                              )}
                              </div>
                            ))}
                             
                          </Form.Item>
                          
                        </div>
                        
                        <div className="col-lg-4 col-md-6">
                          <StringFormItem
                            label="Place"
                            name="place"
                            disabled={""}
                            placeholder="Place"

                          />

                        </div>
                        <div className="col-lg-4 col-md-6">
                          <Form.Item label="Select a Currency" name="defaultCurrencyId" rules={[
                            {
                              required: true,
                              message: "Please enter currency",
                            },
                          ]}>
                            <SearchableDropdown
                              name="defaultCurrencyId"
                              options={currencyOptions}
                              onChange={handleChange}
                            />
                          </Form.Item>
                        </div>
                        
                        {/* <div className="col-lg-4 col-md-6">
                          
                          <label className="select-currncy">Select an currency</label>
                          
                          <SearchableDropdown name="defaultCurrencyId" options={currencyOptions} onChange={handleChange} />
                          
    
                          <Form.Item label="Default Currency ID" name="defaultCurrencyId" key="defaultCurrencyId" style={{ display: 'none' }}>
                            <input type="text" readOnly />
                          </Form.Item>
                          
                          
                        </div> */}
                      </div>

                      <div className="row">
                          <div className="col-lg-12">
                            <Form.Item
                              name="acceptTerms"
                              valuePropName="checked"
                              rules={[
                                {
                                  validator: (_, value) =>
                                    value
                                      ? Promise.resolve()
                                      : Promise.reject(new Error("Please accept terms and conditions")),
                                },
                              ]}
                            >
                              <Checkbox>
                                I agree to the{" "}
                                <span
                                  style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
                                  onClick={() => setShowTermsModal(true)}
                                >
                                  terms and conditions
                                </span>
                              </Checkbox>
                              
                            </Form.Item>
                          </div>
                        </div>

                        <Modal
                          title="Terms and Conditions"
                          visible={showTermsModal}
                        footer={null}
                        onCancel={() => setShowTermsModal(false)}
                        >
                          <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                          <h3>Menu Dealz</h3>
                          <p><strong>Last Updated: 15th November, 2024</strong></p>
                          <p>
                            These Terms and Conditions ("Terms") govern your use of the Menu Dealz
                            platform ("App"), provided by InnovaTech Software Solutions ("we," "our," or
                            "us"). By accessing or using the App, you ("Restaurant" or "Customer") agree
                            to comply with and be bound by these Terms.
                          </p>

                          <h6>1. Restaurant Onboarding</h6>
                          <p>
                            Restaurants must provide accurate and up-to-date information, including
                            business registration details and any required legal documents, during the
                            registration process. By submitting the required documents on our platform,
                            you acknowledge and confirm that the documents are authentic and that you
                            have the necessary authority to register the business on our platform. We
                            reserve the right to approve or reject any restaurant registration based on
                            our verification process. The approval will grant the restaurant access to
                            add menus, special offers, daily deals, and more.
                          </p>

                          <h6>2. Content Submission</h6>
                          <p>
                            Upon approval, restaurants may upload their menus, special offers, and
                            promotions to the App. You are solely responsible for ensuring the accuracy,
                            completeness, and legality of the content you upload. By submitting content
                            to the App, you grant Menu Dealz a worldwide, non-exclusive, royalty-free
                            license to use, display, and distribute this content within the App to
                            provide services to customers.
                          </p>

                          <h6>3. QR Code Generation</h6>
                          <p>
                            Once the restaurant's details are entered into the system, a unique QR code
                            will be generated for the restaurant. This QR code will allow customers to
                            access your menus, offers, and promotions live at your restaurant by
                            scanning the code. The restaurant is responsible for ensuring that the QR
                            code is properly displayed and that all content is kept up to date.
                          </p>

                          <h6>4. Customer Responsibilities</h6>
                          <p>
                            Customers scanning the QR code are responsible for reviewing and
                            understanding the restaurant's menus, offers, and any terms related to their
                            purchases. Customers must ensure that their devices support QR code scanning
                            and can access the App properly.
                          </p>

                          <h6>5. Accuracy of Information</h6>
                          <p>
                            We do not guarantee the accuracy, completeness, or availability of the
                            information provided by restaurants on the App. Menu Dealz shall not be held
                            responsible for any discrepancies, pricing errors, or out-of-date
                            information.
                          </p>

                          <h6>6. Third-Party Services</h6>
                          <p>
                            The App may provide access to third-party services, such as payment
                            processors or delivery services, which are subject to the third party's
                            terms and conditions. We are not responsible for any third-party services
                            and do not provide warranties or guarantees regarding their performance or
                            accuracy.
                          </p>

                          <h6>7. Free Trial Period</h6>
                          <p>
                            Enjoy a free trial period of two months upon joining. After this period, a
                            subscription fee of 200 AED per month will be charged.
                          </p>

                          <h6>8. Exit Policy</h6>
                          <p>
                            Brands can quit anytime without any penalty or charges.
                          </p>

                          <h6>9. Intellectual Property</h6>
                          <p>
                            All rights, titles, and interests in and to the Menu Dealz platform,
                            including but not limited to software, design, and content, are owned by or
                            licensed to Menu Dealz. You are granted a limited, non-transferable right to
                            access and use the App as outlined in these Terms.
                          </p>

                          <h6>10. Limitation of Liability</h6>
                          <p>
                            Menu Dealz shall not be liable for any indirect, incidental, or
                            consequential damages arising from your use of the App, whether as a
                            restaurant or a customer. We do not assume responsibility for any
                            disruptions or failures in service, including but not limited to technical
                            difficulties or force majeure events.
                          </p>

                          <h6>11. Termination</h6>
                          <p>
                            We reserve the right to suspend or terminate any restaurant's or customer's
                            access to the App for violations of these Terms or any applicable laws and
                            regulations.
                          </p>

                          <h6>12. Modifications to Terms</h6>
                          <p>
                            We may update these Terms from time to time to reflect changes in our
                            services or legal requirements. Continued use of the App following any
                            changes to these Terms will constitute acceptance of the revised terms.
                          </p>

                          <h6>13. Governing Law</h6>
                          <p>
                            These Terms are governed by the laws of the United Arab Emirates. Any
                            disputes arising from or relating to these Terms shall be resolved in the
                            courts of the United Arab Emirates.
                          </p>

                          <h6>14. Contact Information</h6>
                          <p>
                            If you have any questions or concerns about these Terms, please contact us
                            at <a href="mailto:info@menudealz.com">info@menudealz.com</a>.
                          </p>
                        </div>
                        </Modal>
  

                      <div className="d-flex justify-content-center">
                        <Button
                          // icon={<ArrowRightOutlined />}
                          style={{ background: "rgb(18, 153, 1)", color: "white" }}
                          // onClick={form.submit}
                          onClick={() => onFinish()}
                        >
                          Register
                        </Button>
                        <ToastContainer />
                      </div>
                    </>
                  )}

                  {activeFormNo === 2 && (
                    <>
                      <BranchData />
                      <div className="text-center mt-5">
                        <button
                          onClick={() => setActiveFromNo(3)}
                          type="submit"
                        >
                          Save & Next
                        </button>
                      </div>
                    </>
                  )}

                  {activeFormNo === 3 && (
                    <>
                      <div className="row ">
                        <div className="col-lg-6 col-md-6 text-center">
                          <Upload {...props}>
                            <Button
                              className="text-start"
                              style={{ width: "310px", height: "60px" }}
                              icon={<UploadOutlined />}
                            >
                              Upload the logo
                            </Button>
                          </Upload>
                          <div className="validate" />
                        </div>
                        <div className="col-lg-6 col-md-6 mt-2 text-center">
                          <Upload {...props}>
                            <Button
                              className="text-start"
                              style={{ width: "310px", height: "60px" }}
                              icon={<UploadOutlined />}
                            >
                              Upload the Trade License(.pdf format)
                            </Button>
                          </Upload>
                          <div className="validate" />
                        </div>
                        <div className="col-lg-6 col-md-6 mt-3 text-center">
                          <Upload {...props}>
                            <Button
                              className="text-start"
                              style={{ width: "310px", height: "60px" }}
                              icon={<UploadOutlined />}
                            >
                              Upload the Identity Document(.pdf format)
                            </Button>
                          </Upload>
                          <div className="validate" />
                        </div>

                        <div className="col-lg-6 col-md-6 mt-3 text-center">
                          <Upload {...props}>
                            <Button
                              className="text-start"
                              style={{ width: "310px", height: "60px" }}
                              icon={<UploadOutlined />}
                            >
                              Upload the Tax registration document(.pdf format)
                            </Button>
                          </Upload>
                          <div className="validate" />
                        </div>
                        <div className="col-lg-6 col-md-6 mt-3 text-center">
                          <Upload {...props}>
                            <Button
                              className="text-start"
                              style={{ width: "310px", height: "60px" }}
                              icon={<UploadOutlined />}
                            >
                              Upload the Signed MenuDeal Contract(.pdf format)
                            </Button>
                          </Upload>
                          <div className="validate" />
                        </div>
                        <div className="col-lg-6 col-md-6 mt-3 text-center">
                          <Upload {...props}>
                            <Button
                              className="text-start"
                              style={{ width: "310px", height: "60px" }}
                              icon={<UploadOutlined />}
                            >
                              Additional Documents 1 (.pdf format)
                            </Button>
                          </Upload>
                          <div className="validate" />
                        </div>
                        <div className="col-lg-6 col-md-6 mt-3 text-center">
                          <Upload {...props}>
                            <Button
                              className="text-start"
                              style={{ width: "310px", height: "60px" }}
                              icon={<UploadOutlined />}
                            >
                              Additional Documents 2 (.pdf format)
                            </Button>
                          </Upload>
                          <div className="validate" />
                        </div>
                      </div>
                      <div className="text-center mt-5">
                        <button
                          onClick={() => setActiveFromNo(4)}
                          type="submit"
                        >
                          Save & Next
                        </button>
                      </div>
                    </>
                  )}

                  {activeFormNo === 4 && (
                    <>
                      <div className="row">
                        {/* <div className='col-md-3'></div> */}
                        <div className="col-md-12">
                          <div className="row">
                            {cussineData.map((data, index) => {
                              return (
                                <div className="col-md-6 mt-3">
                                  <Checkbox>{data.name} </Checkbox>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        {/* <div className='col-md-3'></div> */}
                      </div>
                      <div className="text-center mt-5">
                        <button
                          onClick={() => setActiveFromNo(5)}
                          type="submit"
                        >
                          Save & Next
                        </button>
                      </div>
                    </>
                  )}
                  {activeFormNo === 5 && (
                    <>
                      <section id="why-us" className="why-us section-bg">
                        <div className="container" data-aos="fade-up">
                          <div className="row gy-4">
                            <div className="col-lg-12 d-flex align-items-center">
                              <div
                                className="icon-box d-flex flex-column justify-content-center align-items-center"
                                style={{ background: "aliceblue" }}
                              >
                                <img src={thumbsUpGif} alt="Thumbs Up" style={{ width: '80px', height: '80px' }} />

                                <h4>
                                  Your details are saved successfully.
                                  <br /> Thank you for registering with us.
                                  <br /> Verify your email using link sent on your email id.
                                </h4>
                                <Button
                                  style={{ background: "rgb(18, 153, 1)", color: "white", margin: "20px 0px" }}>
                                  <Link to={'/login'}>Login</Link>
                                </Button>
                                {/* <p>Consequuntur sunt aut quasi enim aliquam quae harum pariatur laboris nisi ut aliquip</p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </>
                  )}
                  {/* <div className="mb-3">
              <div className="loading">Loading</div>
              <div className="error-message" />
              <div className="sent-message">
                Your booking request was sent. We will call back or send an
                Email to confirm your reservation. Thank you!
              </div>
            </div> */}
                </Form>
              </div>
              {/* End Reservation Form */}
            </div>
          </div>
        </section>
        {/* End Book A Table Section */}
      </>
    </div>
  );
}
