import React, { useEffect, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
import SideMenu from "../sidemenu";
import "./index.scss";
// import { SidebarSection } from "../../constants/constant";
import SideBarHeader from "../header/LoggedInHeader";
import api from "../../api";
import DishRegister from "./registerDish";
import Loader from "../Loader";
import Pagination from "./pagination";
import { DishDetailsInterface, DishInterface } from "./interface";
// import DishUpdate from "./updateDish";
import DishDetailView from "./dishDetailView";
import { ToastContainer, toast } from "react-toastify";
import { Button, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
// declare var bootstrap: any;



function RestaurantSpecialDish() {
  // const [count, setCount] = useState(1);

  const [showNav, setShowNav] = useState(true);
  const [loading, setLoading] = useState(true);
  // const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // const [searchText, setSearchText] = useState("");
  const [dishData, setDishData] = useState<DishInterface[]>([
    {
      _id: "1",
      name: "Biryani",
      productionCost: 200,
      status: "true",

    },
    {
      _id: "2",
      name: "Dal Bati",
      productionCost: 150,
      status: "false",


    },
  ]);
  const [dishDetailData, setDishDetailData] =
    useState<DishDetailsInterface | null>(null);
  const [dishId, setDishId] = useState<string | null>(null);
  // const [actionType, setActionType] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [dishStatus, setDishStatus] = useState("");
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [uploadImageModal, setUploadImageModal] = useState(false);
  const [defaultCurrency, setDefaultCurrency] = useState("");
  const ITEMS_PER_PAGE = 10;
  // update dis state
  const keys = {
    images: "images",
    videos: "videos",

  };



  const [formData, setFormData] = useState({
    name: "",
    description: "",
    productionCost: null,
    preparationTime: 0,
    servingSize: 0,
    categoryTag: [""],
    ingrediants: [{ name: "", description: "", quantity: 0, unit: "" }],
  });
  useEffect(() => {
    // Set initial form data when dishDetailData is available

    if (dishDetailData) {
      setFormData({
        name: dishDetailData.name,
        description: dishDetailData.description,
        productionCost: dishDetailData.productionCost,
        preparationTime: dishDetailData.preparationTime,
        servingSize: dishDetailData.servingSize,
        categoryTag: dishDetailData.categoryTag,
        ingrediants: dishDetailData.ingrediants.map((ingredient) => ({
          name: ingredient.name,
          description: ingredient.description,
          quantity: ingredient.quantity,
          unit: ingredient.unit,
        })),
      });
    }
  }, [dishDetailData]);

 
  const [imageList, setImageList] = useState([]);
  const [videoList, setVideoList] = useState([]);

  const props = (key: any) => ({
    name: key,
    //action:process.env.REACT_APP_API_URL + "api/utility/dish/" + dishId,
    action: "/api/utility/dish/" + dishId,
    method: "PUT",
    headers: {
      authorization: "authorization-text",
    },
    multiple: true,
    onChange(info: any) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} File uploaded successfully`);
      } else if (status === "error") {
        message.error(`${info.file.name} File upload failed.`);
      }

      if (key === "images") {
        setImageList(info.fileList); // Update imageList state
      } else if (key === "videos") {
        setVideoList(info.fileList); // Update videoList state
      }
    },
  });

  const handleUpload = async () => {
    setLoading(true);
    try {
      const formData = new FormData();

      // Add images to formData
      imageList.forEach((image) => {
        formData.append("images", image.originFileObj);
      });

      // Add videos to formData
      videoList.forEach((video) => {
        formData.append("videos", video.originFileObj);
      });

      const response = await fetch(`/api/utility/dish/${dishId}`, {
        method: "PUT",
        headers: {
          authorization: "authorization-text",
        },
        body: formData,
      });

      if (response.ok) {
        setLoading(false);
        message.success("Files uploaded successfully");
        handleCloseUpload();
      } else {
        message.error("File upload failed");
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      message.error("File upload failed");
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setDishId(null);
  };
  const handleCloseUpload = () => {
    setUploadImageModal(false);
  };
  const handleShow = (itemId: string | null) => {
    setShowModal(true);
    setDishId(itemId!);
  };
  const handleDeleteClose = () => {
    setShowDeleteModal(false);
    setDishId(null);
  };
  const handleDeleteShow = (itemId: string) => {
    setShowDeleteModal(true);
    setDishId(itemId!);
  };
  const handleUploadShow = (itemId: string) => {
    setImageList([]);
    setVideoList([]);
    setUploadImageModal(true);
    setDishId(itemId!);
  };
  const handleRegisterClose = () => {
    //dishListFetchData(currentPage);
    setShowRegisterModal(false);
  };
  const handleRegisterShow = () => {
    setShowRegisterModal(true);
  };
  const handleUpdateClose = () => {
    setShowUpdateModal(false);
    setDishId(null);
  };
  const handleUpdateShow = (itemId: string) => {
    setShowUpdateModal(true);
    setDishId(itemId!);
  };

  const handleStatusUpdateClose = () => setShowStatusUpdateModal(false);
  const handleStatusUpdateShow = (itemId: string) => {
    setShowStatusUpdateModal(true);
    setDishId(itemId!);
  };
  const updateStatus = async () => {
    setLoading(true);
    console.log(" update status ");
    try {
      await api
        .put(
          "/api/restaurant/dish/status/" + dishId + "?statusCode=" + dishStatus
        )
        .then(function (response) {
          toast.success("Status updated Successfully!");
          setLoading(false);
          console.log(response.data);
          handleStatusUpdateClose();
          setDishId(null);
          dishListFetchData(currentPage);
          return response.data;
        })
        .catch(function (error) {
          console.log("Show error notification!");
          toast.error("Error in api call!");
          setLoading(false);
          console.log(error);
          return Promise.reject(error);
        });
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  const dishListFetchData = async (page: number) => {
    try {
      console.log("fetch detailss");
      setLoading(true);
      const response = await api.get(
        `/api/restaurant/dish/?page=${page}&limit=${ITEMS_PER_PAGE}`
      );
      const dishData = response.data;
      const totalCount = dishData.data.totalCount;
      // console.log("dishData", dishData);
      setDishData(dishData.data.dish || []);
      setTotalPages(Math.ceil(totalCount / ITEMS_PER_PAGE));
      // setError(
      //   dishData.length > 0
      //     ? null
      //     : "No results found for the given search text."
      // );
    } catch (error: any) {
      console.error("Error fetching data:", error);
      // setError("An unexpected error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   dishListFetchData(currentPage);
  // }, [currentPage]);

  const dishDetailFetchData = async (id: string | null) => {
    try {
      setLoading(true);
      if (id !== null) {
        const response = await api.get(`/api/restaurant/dish/${id}`);
        const dishDetailData = response.data;
        setDishDetailData(dishDetailData.data);
        // console.log("dishDetailData", dishDetailData.data);
      }
    } catch (error: any) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dishDetailFetchData(dishId);
  }, [dishId]);

  const handleDelete = async () => {
    try {
      if (dishId !== null) {
        setLoading(true);
        await api.delete(`/api/restaurant/dish/${dishId}`);
        setDishId(null);
        await dishListFetchData(currentPage);
        handleDeleteClose();
      }
    } catch (error) {
      console.error("Error deleting restaurant:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]:
        name === "productionCost" ||
          name === "preparationTime" ||
          name === "servingSize"
          ? parseInt(value, 10)
          : value,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const updateData = {
      name: formData.name,
      description: formData.description,
      productionCost: formData.productionCost,
      preparationTime: formData.preparationTime,
      servingSize: formData.servingSize,
      categoryTag: formData.categoryTag,
      ingrediants: formData.ingrediants.map((ingredient: any) => ({
        name: ingredient.name,
        description: ingredient.description,
        quantity: ingredient.quantity,
        unit: ingredient.unit,
      })),
    };

    try {
      setLoading(true);

      const response = await api.put(
        `/api/restaurant/dish/${dishId}`,
        updateData
      );

      console.log("Updated dish response", response);
      toast.success(response.data.message);
    } catch (error: any) {
      console.error("There was an error during update!", error.response.data);

      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Please fill out all required fields.");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const loggedInUserData = localStorage.getItem("loggedInUser");
    if (loggedInUserData) {
      const parsedData = JSON.parse(loggedInUserData);
      setDefaultCurrency(parsedData.restaurantdefaultCurrencyCode);
    }
  }, []);



  // const getLocalFormattedDate = (date: string | undefined) => {
  //   if (date) {
  //     var localDate = new Date(date);

  //     const year = localDate.getFullYear();
  //     const month = String(localDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth returns 0-based index
  //     const day = String(localDate.getDate()).padStart(2, '0');
  //     const formattedDate = `${year}-${month}-${day}`;
  //     return formattedDate;
  //   }


  //   return "";
  // }

 

  return (
    <div>
      <div>
        <div className={`body-area${showNav ? " body-pd" : ""}`}>
          <SideBarHeader showNav={showNav} setShowNav={setShowNav} />
          <div className={`l-navbar${showNav ? " showSideNav" : ""}`}>
            <nav className="sideNav">
              <SideMenu />
            </nav>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="pt-4 pb-4">
              <section className="main shadow-sm">
                <div className="row mb-4">
                  <div className="col">
                    
                    <h4>Restaurant/Chef's Special Dishes</h4>
                  </div>
                  <div className="col text-end">
                    <button
                      type="button"
                      className="btn addbtn"
                      // onClick={prepareAddDish}
                      onClick={handleRegisterShow}
                    // onClick={() => {
                    //   viewDishDetails("1", "register");
                    // }}
                    >
                      <i className="bi bi-clipboard-plus"></i>
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr className="table-active">
                          <th scope="col">#</th>
                          <th scope="col">NAME</th>
                          <th scope="col">PRICE</th>

                          <th scope="col" style={{ padding: "0 40px" }}>
                            STATUS
                          </th>
                          <th scope="col">ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dishData.map((item, index) => (
                          <tr key={item._id}>
                            <td>
                            {(currentPage - 1) * 10 + index + 1}
                            </td>
                            <td>{item.name}</td>
                            <td>{item.productionCost}</td>


                            <td>
                              {item.status === 'active' ? (
                                <span className="badge bg-success rounded-pill">
                                  Active
                                </span>
                              ) : (
                                <span className="badge bg-error rounded-pill">

                                </span>
                              )}

                              {item.status === 'suspended' ? (
                                <span className="badge bg-error rounded-pill">
                                  Suspended
                                </span>
                              ) : (
                                <span className="badge bg-error rounded-pill">

                                </span>
                              )}

                              {item.status === 'creating' ? (
                                <span className="badge bg-warning rounded-pill">

                                  Creating
                                </span>
                              ) : (
                                <span className="badge bg-error rounded-pill">

                                </span>
                              )}
                              &nbsp;
                              <button
                                
                                className="btn-action rounded-circle act-btn"
                                onClick={() => handleStatusUpdateShow(item._id)}
                              >
                                <i className="bi bi-pencil-square"></i>
                              </button>
                            </td>

                            <td className="p-1 d-flex">
                              <button
                                className="btn-action rounded-circle act-btn m-1"
                                onClick={() => {
                                  // viewDishDetails(item._id, "view");
                                  handleShow(item._id);
                                }}
                              >
                                <i className="bi bi-eye"></i>
                              </button>{" "}
                              &nbsp;
                              <button
                                className="btn-action rounded-circle act-btn m-1"
                                onClick={() => {
                                  // viewDishDetails(item._id, "edit");
                                  handleUpdateShow(item._id);
                                }}
                              >
                                <i className="bi bi-pencil-square"></i>
                              </button>
                              <button
                                className="btn-action rounded-circle act-btn m-1"
                                onClick={() => {
                                  // viewDishDetails(item._id, "delete");
                                  handleDeleteShow(item._id);
                                }}
                              >
                                <i className="bi bi-trash"></i>
                              </button>
                              <button
                                className="btn-action rounded-circle act-btn m-1"
                                onClick={() => {
                                  // viewDishDetails(item._id, "delete");
                                  handleUploadShow(item._id);
                                }}
                              >
                                <i className="bi bi-upload"></i>
                              </button>
                            </td>
                          </tr>
                        ))}


                      </tbody>
                    </table>
                  </div>
                </div>
              </section>
            </div>
          )}
        </div>
        <div className=" d-flex  justify-content-end pe-md-5">
          {!loading && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>
      </div>
      {showModal && (
        <div
          className="modal"
          tabIndex={-1}
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                Restaurant/Chef's Special Dishes Details
                </h1>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleClose}
                ></button>
              </div>
              <>
                <DishDetailView
                  dishDetailData={dishDetailData}
                  handleClose={handleClose}
                />
              </>
            </div>
          </div>
        </div>
      )}

      {showDeleteModal && (
        <div
          className="modal"
          tabIndex={-1}
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Delete Today Special?
                </h1>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleDeleteClose}
                ></button>
              </div>
              <div className="delete-text">
                {" "}
                Are you sure you want to delete?
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleDeleteClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={handleDelete}
                  className="btn btn-primary"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showStatusUpdateModal && (
        <div
          className="modal"
          tabIndex={-1}
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Update status?
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleStatusUpdateClose}
                ></button>
              </div>
              <div className="delete-text">
                <div className="mb-12 col-md-12">
                  <label className="form-label">
                    Select status <span className="text-danger"> *</span>
                  </label>
                  <div>
                    <select
                      className="form-control down-arrow"
                      value={dishStatus}
                      onChange={(e) => setDishStatus(e.target.value)}
                    >
                      <option value="creating">Creating</option>

                      <option value="active">Active</option>

                      <option value="suspended">Suspended</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleStatusUpdateClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={updateStatus}
                  className="btn btn-primary"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showRegisterModal && (
        <div
          className="modal"
          tabIndex={-1}
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Add Restaurant Special
                </h1>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleRegisterClose}
                ></button>
              </div>
              <>
                <DishRegister handleRegisterClose={handleRegisterClose} />
              </>
            </div>
          </div>
        </div>
      )}
      {showUpdateModal && (
        <div
          className="modal"
          tabIndex={-1}
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          {loading && <Loader />}
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Update Restaurant Special Dish
                </h1>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleUpdateClose}
                ></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className="row">
                    <div className="col">
                      <div className="mb-3">
                        <label className="form-label">
                          Select The Dish<span className="text-danger"> *</span>
                        </label>
                        <select
                          name="name"
                          className="form-control"
                          id="nameOfDish"
                          value={formData.name}
                          onChange={(e) => handleInputChange(e)}>
                          <option value="" disabled>Select Dish</option>
                          <option key="Dal Baati" value="Dal Baati">Dal Baati</option>
                          <option key="Biryani" value="Biryani">Biryani</option>
                          <option key="Chole bhature" value="Chole bhature">Chole bhature</option>
                          <option key="Dal makhani" value="Dal makhani">Dal makhani</option>
                          <option key="Paneer butter masala" value="Paneer butter masala">Biryani</option>
                        </select>
                      </div>

                      <div className="mb-3">
                        <label className="form-label">
                          Price Of The Dish<span className="text-danger"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="Price"
                          value={formData.productionCost}
                          placeholder="Enter Price Of The Dish"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>


                  </div>



                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={handleUpdateClose}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Save changes
                  </button>
                  <ToastContainer />
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {uploadImageModal && (
        <div
          className="modal"
          tabIndex={-1}
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Dish image and videos
                </h1>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleCloseUpload}
                ></button>
              </div>
              <>
                <section className="main shadow-sm">
                  <>

                    <div className="row ">
                      <div className="col-lg-6 col-md-6 text-center">
                        <Upload {...props(keys.images)} method="PUT">
                          <Button
                            className="text-start"
                            style={{ width: "310px", height: "60px" }}
                            icon={<UploadOutlined />}
                            name={keys.images}
                          >
                            Dish image (PNG, JPG, JPEG)

                            <span className="text-danger"> *</span>
                          </Button>
                        </Upload>
                        <div className="validate" />
                      </div>
                      <div className="col-lg-6 col-md-6 text-center">
                        <Upload {...props(keys.videos)} method="PUT" >
                          <Button
                            className="text-start"
                            style={{ width: "310px", height: "60px" }}
                            icon={<UploadOutlined />}
                            name={keys.videos}
                          >
                            Dish video
                          </Button>
                        </Upload>
                        <div className="validate" />
                      </div>
                      {/* <Button onClick={handleUpload}>Upload</Button> */}
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                        onClick={handleCloseUpload}
                      >
                        Close
                      </button>
                      <button onClick={handleUpload} type="submit" className="btn btn-primary">
                        Upload
                      </button>
                      <ToastContainer />
                    </div>
                  </>
                </section>
              </>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}

export default RestaurantSpecialDish;
