import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import SideMenu from "../sidemenu";
import "./branch.scss";
import { MultiSelect } from "react-multi-select-component";
import { SidebarSection } from "../../constants/constant";
import SideBarHeader from "../header/LoggedInHeader";
import { ToastContainer, toast } from "react-toastify";
import { RestaurantBranchMenuInterface } from "./RestaurantBranchMenuInterface";
import api from "../../api";
// import { MenuInterface } from "../menu/interFace";
import { BranchMenuInterface } from "./BranchMenuInterface";
import Loader from "../Loader";
import { Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import Item from "antd/es/list/Item";
import Pagination from "./pagination";
import { Console } from "console";
// declare var bootstrap: any;

interface Option {
  value: string;
  label: string;
  code: string;
  isActive: boolean;
}
interface Data {
  _id: string;
  dishes: Array<Data1>;
}
interface CategoryInterface {
  _id: string;
  name: string;
  code: string;
  status: string;
  description: string;
}

interface Data1 {
  _id: string;
  label: string;
  description: string;
  price: number;
}
interface menuOption {
  value: string;
  label: string;
  code: string;
  description: string;
  price: number;
}
interface DetailsInterface {
  branch: [
    {
      _id: string;
      isActive: boolean;
      startDate: string;
      endDate: string;
    }
  ];
}

// interface Item {
//   _id: string;
//   name: string;
//   code: string;
//   isActive: boolean;

// }
export interface MyListItemTypeProps {
  name: string;
  _id: string;
}
function Dish() {
  // const [userData, setUserData] = useState(null);
  const [selected, setSelected] = useState<Option[]>([]);
  const [restaurantId, setRestaurantId] = useState("");
  const [count, setCount] = useState(1);
  const ITEMS_PER_PAGE = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState("");
  const [UpdateMenuType, setUpdateMenuType] = useState("");
  const [branchMenuData, setBranchMenuData] = useState<
    RestaurantBranchMenuInterface[]
  >([]);
  const [branchMenuId, setBranchMenuId] = useState("");
  const [menuListData, setMenuListData] = useState<BranchMenuInterface[]>([]);
  const [menuId, setMenuId] = useState("");
  const [loading, setLoading] = useState(false);
  const handleDeleteClose = () => {
    setBranchMenuId("");
    setShowDeleteModal(false);
  };
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteBranchModal, setShowDeleteBranchModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => {
    setShowModal(true);
    setSelectedMenuType("");
  };
  const showEditModal = (menutype: string) => {
    setShowModal(true);
    setMenuTypeCode(menutype);
    console.log("***edit modal open");
    console.log(menutype);
  };
  const handleClose = () => {
    setShowModal(false);
    setSelectedMenuType("");
    setMenuId("");
    setSelected([]);
    setBranchMenuId("");
  };
  const [menu, setMenu] = useState(false);
  const [branchOptions, setBranchOptions] = useState<Option[]>([]);
  let [errors, setErrors] = useState({ menuId: "", selected: "" });
  const [showViewBranchModal, setShowViewBranchModal] = useState(false);
  const handleViewBranchClose = () => {
    setShowViewBranchModal(false);

    setMenuId("");
  };
  const [branchNames, setBranchNames] = useState<MyListItemTypeProps[]>([]);
  const navigate = useNavigate();
  const [branchMenuDetails, setBranchMenuDetails] =
    useState<BranchMenuInterface>();
  const [remainingBranches, setRemainingBranches] = useState(branchNames);
  const [menuTypeCode, setMenuTypeCode] = useState("");
  const [selectedMenuType, setSelectedMenuType] = useState("");
  const [showNav, setShowNav] = useState(true);

  // const handleNext = () => {
  //   setCount((prevCount) => prevCount + 1);
  // };
  const [DetailData, setDetailData] = useState<DetailsInterface | null>(null);
  useEffect(() => {
    // Set initial form data when dishDetailData is available

    if (DetailData) {
      const transformedOptions: Option[] = DetailData.branch.map((branch) => {
        // Find the corresponding branch option by branchId
        const correspondingBranchOption = branchOptions.find(
          (option) => option.value === branch._id
        );

        return {
          value: branch._id,
          label: correspondingBranchOption
            ? correspondingBranchOption.label
            : branch._id, // Use the label from branchOptions or fallback to branchId
          code: correspondingBranchOption
            ? correspondingBranchOption.code
            : branch._id, // Use the code from branchOptions or fallback to branchId
          isActive: branch.isActive, // Set the active status from dishDetailData
        };
      });

      setSelected(transformedOptions);
    }
  }, [DetailData]);

  useEffect(() => {
    const loggedInUserData = localStorage.getItem("loggedInUser");
    if (loggedInUserData) {
      // setUserData(JSON.parse(loggedInUserData));
      const parsedData = JSON.parse(loggedInUserData);
      setRestaurantId(parsedData.restaurantId);
    }
    getAllActiveMenu();
  }, []);
  useEffect(() => {
    setRemainingBranches(branchNames);
  }, [branchNames]);

  useEffect(() => {
    if (restaurantId) {
      console.log("logged restaurant iddd ", restaurantId);
      getAllRestaurantMenu(currentPage);
      getAllBranches();
    }
  }, [restaurantId, currentPage, search]);

  const handleChange = (e: any) => {
    console.log("eee " + e);
    if (e.target === undefined) {
      console.log("multiselected selectedd " + e);
      JSON.stringify(e);
      setSelected(e);
    } else {
      const { name, value } = e.target;

      if (name === "menuId") {
        setMenuId(value);
      }
    }
  };

  //Function for Get all branches
  const getAllRestaurantMenu = async (pageNo: any) => {
    //setLoading(true);
    try {
      var url = "";
      console.log("search value " + search);
      // if(search.trim()===''){
      //   url = `api/identity/restaurant-branch/?page=${pageNo}&limit=${ITEMS_PER_PAGE}&restaurantId=${restaurantId}`;
      // }else{
      url =
        `api/restaurant/branch-menu/?page=${pageNo}&limit=${ITEMS_PER_PAGE}&searchText=` +
        search;
      // }
      const response = await api.get(url);
      //setLoading(false);
      console.log("success response: ", response.data.data);
      // for(var i=0;i<response.data.data.assignment.length;i++){
      //   response.data.data.assignment[i].branch = "Branch "+i;
      // }
      // Update branch data
      setBranchMenuData(response.data.data.assignment);

      // Update total pages
      const totalCount = response.data.data.totalCount;
      const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE);
      setTotalPages(totalPages);
      console.log("totalPages count ", totalPages);

      return response.data;
    } catch (error) {
      console.error("There was an error!", error);
      message.error(error.response.data.message);
      //setLoading(false);
      return Promise.reject(error);
    }
  };

  const nextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const goToPage = (pageNumber: any) => {
    setCurrentPage(Math.min(Math.max(pageNumber, 1), totalPages));
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value;
    console.log("Search Text: ", searchText); // Log the search text
    setSearch(searchText);
    setCurrentPage(1); // Reset to the first page whenever search text changes
  };

  const getAllActiveMenu = async () => {
    try {
      const response = await api.get(
        "api/restaurant/menu/active?menuTypeCode=defaultMenu"
      );
      console.log("success response", response.data);
      setMenuListData(response.data.data);
    } catch (error) {
      console.error("Error fetching menu data:", error);
      message.error(error.response.data.message);
    }
  };
  const getAllActiveBuffetMenu = async () => {
    try {
      const response = await api.get(
        "api/restaurant/menu/active?menuTypeCode=buffetMenu"
      );
      console.log("success response", response.data);
      setMenuListData(response.data.data);
    } catch (error) {
      console.error("Error fetching menu data:", error);
      message.error(error.response.data.message);
    }
  };

  const getAllBranches = async () => {
    try {
      const response = await api.get(
        "api/identity/restaurant-branch/?page=1&limit=100&restaurantId=" +
          restaurantId
      );
      console.log("success response", response.data);

      const formattedOptions = response.data.data.branch.map((item) => ({
        value: item._id,
        label: item.name,
        code: item.code,
      }));
      setBranchOptions(formattedOptions);
    } catch (error) {
      console.error("Error fetching dish data:", error);
      message.error(error.response.data.message);
    }
  };

  const handleDeleteShow = (id: string) => {
    setShowDeleteModal(true);
    setBranchMenuId(id);
  };

  const viewBranchMenu = (branchId: string, menuId: string) => {
    console.log(
      "branchId: " +
        branchId +
        " branchMenuId: " +
        branchMenuId +
        " restautantId: " +
        restaurantId
    );
    navigate(
      "/restaurant-menu?a=" +
        branchMenuId +
        "&b=" +
        branchId +
        "&c=" +
        restaurantId
    );
  };

  const deleteBranchMenu = async () => {
    setLoading(true);
    try {
      await api
        .delete("api/restaurant/branch-menu/" + branchMenuId)
        .then(function (response) {
          console.log("sucess respp");
          message.success(response.data.message);
          setLoading(false);
          console.log(response.data);
          setBranchMenuId("");
          handleDeleteClose();
          getAllRestaurantMenu(currentPage);
          return response.data;
        })
        .catch(function (error) {
          console.log("Show error notification!");
          message.error(error.response.data.message);
          setLoading(false);
          console.log(error);
          return Promise.reject(error);
        });
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  const isValid = () => {
    var valid = true;
    var newErrors = {
      menuId: "",
      selected: "",
    };

    // Menu
    if (!menuId.trim()) {
      newErrors = { ...newErrors, menuId: "Menu is required" };
      valid = false;
    } else {
      newErrors = { ...newErrors, menuId: "" }; // Clear the error if address is provided
      valid = false;
      console.log("menu error cleared");
    }

    // Dish
    if (selected.length === 0) {
      newErrors = { ...newErrors, selected: "Branch is required" };
      valid = false;
    } else {
      newErrors = { ...newErrors, selected: "" }; // Clear the error if contact number is provided
      valid = true;
    }

    if (!valid) {
      setErrors(newErrors);
      console.log("error count: " + Object.keys(newErrors).length);
    }

    return valid;
  };

  const addOrUpdateBranchMenu = async () => {
    if (isValid()) {
      //setLoading(true);

      let url = "";
      // let menuTypeCode = menu ? "buffetMenu" : "defaultMenu";

      if (branchMenuId === "" || branchMenuId === undefined) {
        url = "api/restaurant/branch-menu/";

        let payload = {
          branchId: selected.map((option) => option.value),
          menuId: menuId,
          menuTypeCode: menuTypeCode,
        };
        console.log(" add menu " + JSON.stringify(payload));

        try {
          const response = await api.post(url, payload);
          setLoading(false);
          message.success(response.data.message);
          console.log("success response", response.data);
          handleClose();
          getAllRestaurantMenu(currentPage);
          clearFormFields();
        } catch (error) {
          console.error("Error adding menu:", error);
          message.error(error.response.data.message);
          setLoading(false);
          // toast.error("Error adding menu!");
        }
      } else {
        let payload = {
          branchId: selected.map((option) => ({
            branchId: option.value,
            isActive: true,
          })),
          menuId: menuId,
          isActive: true,
          menuTypeCode: UpdateMenuType,
        };
        console.log("***payload");
        console.log(payload);

        url = "api/restaurant/branch-menu/" + branchMenuId;
        console.log(" update branch menu " + JSON.stringify(payload));
        try {
          const response = await api.put(url, payload);
          setLoading(false);
          message.success(response.data.message);
          console.log("success response", response.data);
          setBranchMenuId("");
          handleClose();
          getAllRestaurantMenu(currentPage);
          clearFormFields();
        } catch (error) {
          console.error("Error updating menu:", error);
          message.error(error.response.data.message);
          setLoading(false);
          // toast.error("Error updating menu!");
        }
      }
    }
  };
  const clearFormFields = () => {
    setBranchMenuId("");
    setMenuId("");
    setSelected([]);
  };

  const handleViewBranchMenuShow = async (id: string, menuTypeCode: string) => {
    setBranchMenuId(id);
    setMenuTypeCode(menuTypeCode);
    console.log("branchhh menu" + id);
    setLoading(true);
    try {
      await api
        .get("api/restaurant/branch-menu/" + id)
        .then(function (response) {
          setLoading(false);
          console.log("sucess respp get branch menu details");
          console.log(response.data);
          setMenuId(response.data.data.menuId);
          getMenuDetails(response.data.data.menuId, menuTypeCode);
          setBranchMenuDetails(response.data.data);
          const newBranchNames = [];
          for (var i = 0; i < response.data.data.branch.length; i++) {
            for (var j = 0; j < branchOptions.length; j++) {
              if (response.data.data.branch[i]._id === branchOptions[j].value) {
                console.log("branchesss " + branchOptions[j].label);
                const branchDetail = {
                  name: branchOptions[j].label,
                  _id: branchOptions[j].value,
                };
                newBranchNames.push(branchDetail);
              }
            }
          }
          setBranchNames(newBranchNames);

          setShowViewBranchModal(true);
          return response.data;
        })
        .catch(function (error) {
          console.log("Show error notification!");
          message.error(error.response.data.message);
          setLoading(false);
          console.log(error);
          return Promise.reject(error);
        });
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  const BranchMenuShow = async (id: string) => {
    setBranchMenuId(id);
    console.log("branchhh menu" + id);
    setLoading(true);
    try {
      await api
        .get("api/restaurant/branch-menu/" + id)
        .then(function (response) {
          setLoading(false);
          console.log("sucess respp get branch menu details");
          console.log(response.data);
          setDetailData(response.data.data);
          setMenuId(response.data.data.menuId);
          let menuTypeCode = response.data.data.menuTypeCode;
          setMenuTypeCode(menuTypeCode);
          if (menuTypeCode == "defaultMenu") {
            setSelectedMenuType("À la carte menu");
            getAllActiveMenu();
          } else if (menuTypeCode == "buffetMenu") {
            setSelectedMenuType("Buffet menu");
            getAllActiveBuffetMenu();
          }

          setBranchMenuDetails(response.data.data);
          const newBranchNames = [];
          for (var i = 0; i < response.data.data.branch.length; i++) {
            for (var j = 0; j < branchOptions.length; j++) {
              if (response.data.data.branch[i]._id === branchOptions[j].value) {
                console.log("branchesss " + branchOptions[j].label);
                const branchDetail = {
                  name: branchOptions[j].label,
                  _id: branchOptions[j].value,
                };
                newBranchNames.push(branchDetail);
              }
            }
          }
          setBranchNames(newBranchNames);

          return response.data;
        })
        .catch(function (error) {
          console.log("Show error notification!");
          message.error(error.response.data.message);
          setLoading(false);
          console.log(error);
          return Promise.reject(error);
        });
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  const prepareEditBranchMenu = async (item: any) => {
    setMenuTypeCode(item.menuTypeCode);
    setUpdateMenuType(item.menuTypeCode);
    console.log("****in edit function");
    console.log(item.menuTypeCode);
    if (item.menuTypeCode === "defaultMenu") {
      setSelectedMenuType("À la carte menu");
      getAllActiveMenu();
    } else if (item.menuTypeCode === "buffetMenu") {
      setSelectedMenuType("Buffet menu");
      getAllActiveBuffetMenu();
    }
    console.log(item.menuTypeCode);
    BranchMenuShow(item._id);
    setBranchMenuId(item._id);
    setMenuId(item.menuId);
    showEditModal(item.menuTypeCode);
  };

  const renderInputs = () => {
    const inputs = [];
    for (let i = 1; i <= count; i++) {
      inputs.push(
        <tr>
          {branchMenuId === "" || branchMenuId === undefined ? (
            <div className="col-md-6 mb-3">
              <label className="form-label">
                Menu Type<span className="text-danger"> *</span>
              </label>
              <select
                className="form-control down-arrow"
                onChange={handleMenuChange}
                value={selectedMenuType}
              >
                <option value="" disabled>
                  Select
                </option>
                <option value="À la carte menu">À la carte menu</option>
                <option value="Buffet menu">Buffet menu</option>
              </select>
            </div>
          ) : null}

          <div className=" row">
            <div className=" col-md-6 mb-3">
              <label className="form-label">
                Menu<span className="text-danger"> *</span>
              </label>
              <div>
                <select
                  className="form-control down-arrow"
                  name="menuId"
                  id="menu"
                  value={menuId}
                  onChange={(e: any) => handleChange(e)}
                >
                  <option value="">Select</option>
                  {menuListData.map((item, index) => (
                    <option key={index} value={item._id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                {errors.menuId && (
                  <span className="error-msg">{errors.menuId}</span>
                )}
              </div>
            </div>
            <div className=" col-md-6 mb-3 ">
              <label className="form-label">
                Branches<span className="text-danger"> *</span>
              </label>
              <MultiSelect
                options={branchOptions}
                value={selected}
                onChange={handleChange}
                labelledBy="Select"
              />
              {errors.selected && (
                <span className="error-msg">{errors.selected}</span>
              )}
            </div>
          </div>
        </tr>
      );
    }
    return inputs;
  };

  const handleMenuChange = (e) => {
    const selectedMenu = e.target.value;
    setSelectedMenuType(selectedMenu);

    if (selectedMenu === "À la carte menu") {
      setMenuTypeCode("defaultMenu");
      getAllActiveMenu();
    } else if (selectedMenu === "Buffet menu") {
      setMenuTypeCode("buffetMenu");
      getAllActiveBuffetMenu();
    }
  };
  const [branchId, setBranchId] = useState("");
  const handleDeleteBranch = () => {
    setRemainingBranches(
      remainingBranches.filter((branch) => branch._id !== branchId)
    );
    setShowDeleteBranchModal(false);
  };
  const UpdateBranchMenu = async () => {
    // let menuTypeCode = menu ? "buffetMenu" : "defaultMenu";
    let payload = {
      branchId: remainingBranches.map((branch) => ({
        branchId: branch._id,
        isActive: true,
      })),
      menuId: menuId,
      isActive: true,
      menuTypeCode: menuTypeCode,
    };

    const url = `api/restaurant/branch-menu/${branchMenuId}`;
    console.log("Update branch menu payload:", JSON.stringify(payload));

    try {
      const response = await api.put(url, payload);
      setLoading(false);
      message.success(response.data.message);
      console.log("Success response:", response.data);
      handleViewBranchClose();
      getAllRestaurantMenu(currentPage);
      clearFormFields();
    } catch (error) {
      console.error("Error updating menu:", error);
      setLoading(false);
      message.error(error.response.data.message);
    }
  };

  const handleDeleteBranchShow = (itemId: string) => {
    setShowDeleteBranchModal(true);
    setBranchId(itemId!);
  };
  const handleDeleteBranchClose = () => {
    setBranchId("");
    setShowDeleteBranchModal(false);
  };
  const [selectedCategories, setSelectedCategories] = useState<Data[]>([]);
  const [categoryId, setCategoryId] = useState("");
  const [selectedmenu] = useState<menuOption[]>([]);
  const [categoryListData, setCategoryListData] = useState<CategoryInterface[]>(
    []
  );
  const [Image, setImage] = useState("");
  const [defaultCurrency, setDefaultCurrency] = useState("");
  useEffect(() => {
    let selectedCats: Data[] = [];
    selectedCategories.forEach((element) => {
      if (element._id === categoryId) {
        let dishes: Data1[] = [];
        selectedmenu.forEach((element1) => {
          dishes.push({
            _id: element1.value,
            description: element1.description,
            label: element1.label,
            price: element1.price,
          });
        });
        let val: Data = {
          _id: categoryId,
          dishes: dishes,
        };
        selectedCats.push(val);
      } else {
        selectedCats.push(element);
      }
    });
    setSelectedCategories(selectedCats);
  }, [selected]);

  const setCategoryDataInEditFlow = async (data: any) => {
    let selectedCats: Data[] = [];
    data.category.forEach((element) => {
      let dishes: Data1[] = [];
      element.dish.forEach((element1) => {
        dishes.push({
          _id: element1._id,
          description: element1.description,
          label: element1.name,
          price: element1.price,
        });
      });
      let val: Data = {
        _id: element._id,
        dishes: dishes,
      };
      selectedCats.push(val);
    });
    setSelectedCategories(selectedCats);
  };
  const getCategoryName = (id: any) => {
    let name = null;
    categoryListData.forEach((element) => {
      if (element._id === id._id) {
        name = element.name;
      }
    });
    return name;
  };
  const renderInputs3 = (i: any) => {
    return selectedCategories[i].dishes.map((dish, j) => (
      <tr key={j}>
        <th scope="row">{j + 1}</th>
        <td>{dish.label}</td>
        {menuTypeCode === "defaultMenu" && <td>{dish.price}</td>}

        <td>{dish.description}</td>
      </tr>
    ));
  };

  const renderInputs2 = (i: any) => {
    return (
      <table className="table table-bordered">
        <thead>
          <tr className="table-active">
            <th scope="col">#</th>
            <th>DISH NAME</th>
            {menuTypeCode === "defaultMenu" && (
              <th>PRICE({defaultCurrency})</th>
            )}

            <th>DESCRIPTION</th>
          </tr>
        </thead>
        <tbody>{renderInputs3(i)}</tbody>
      </table>
    );
  };

  const renderbranchmenu = () => {
    const inputs = [];
    for (let i = 0; i < selectedCategories.length; i++) {
      inputs.push(
        <div
          key={i}
          className={`tab-pane fade ${i === 0 ? "show active" : ""}`}
          id={"tab" + i}
          role="tabpanel"
        >
          <div className="card">
            <div
              id={"collapseOne" + i}
              className="collapse show"
              aria-labelledby={"headingOne" + i}
            >
              <div
                className="card-body opto-2"
                style={{ padding: 0, maxHeight: "335px" }}
              >
                {renderInputs2(i)}
              </div>
            </div>
          </div>
        </div>
      );
    }
    return inputs;
  };
  useEffect(() => {
    // debugger;
    getAllActiveCategory();
    getAllActiveDishes();
    const loggedInUserData = localStorage.getItem("loggedInUser");
    if (loggedInUserData) {
      // setUserData(JSON.parse(loggedInUserData));
      const parsedData = JSON.parse(loggedInUserData);
      setDefaultCurrency(parsedData.restaurantdefaultCurrencyCode);
    }
  }, [menuId]);

  const getAllActiveCategory = async () => {
    try {
      const response = await api.get("/api/restaurant/menu-category/active");
      console.log("success response", response.data);
      setCategoryListData(response.data.data);
    } catch (error) {
      console.error("Error fetching category data:", error);
      message.error("Error fetching category data!");
    }
  };
  const getAllActiveDishes = async () => {
    try {
      const response = await api.get(
        "/api/restaurant/dish/active?page=1&limit=1000"
      );
      console.log("success response", response.data);
    } catch (error) {
      console.error("Error fetching dish data:", error);
      message.error("Error fetching dish data!");
    }
  };
  const getMenuDetails = async (menuId: string, menuTypeCode: string) => {
    let menutype;
    if (menuTypeCode === "buffetMenu") {
      menutype = "buffet-menu";
    } else {
      menutype = "menu";
    }
    try {
      var url = "/api/restaurant/" + menutype + "/" + menuId;
      const response = await api.get(url);
      console.log("success menu details response", response.data);
      setCategoryId(response.data.data.category[0]._id);
      setCategoryDataInEditFlow(response.data.data);
      {
        menuTypeCode === "buffetMenu" &&
          setImage(response.data.data.imageAttachement[0].url);
      }
    } catch (error) {
      console.error("Error fetching dish data:", error);
      message.error(error.response.data.message);
    }
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className={`body-area${showNav ? " body-pd" : ""}`}>
            <SideBarHeader showNav={showNav} setShowNav={setShowNav} />
            <div className={`l-navbar${showNav ? " showSideNav" : ""}`}>
              <nav className="sideNav">
                <SideMenu />
              </nav>
            </div>
            <div className="pt-4 pb-4">
              <section className="main shadow-sm">
                <div className="row mb-4">
                  <div className="col">
                    {/* <h4>Branch Menu List</h4> */}
                    <h4>{SidebarSection.BRANCH_MENU_LIST}</h4>
                  </div>
                  <div className="col text-end d-flex">
                    <input
                      placeholder="Search by name..."
                      value={search}
                      onChange={(e: any) => handleSearchChange(e)}
                      type="text"
                      name="search"
                      className="form-control search"
                      id="Search"
                      aria-describedby="emailHelp"
                    />
                    <button
                      type="button"
                      className="btn addbtn"
                      onClick={handleShow}
                    >
                      <i className="bi bi-clipboard-plus"></i>
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr className="table-active">
                          <th scope="col">#</th>
                          <th scope="col">MENU NAME</th>
                          <th scope="col">DESCRIPTION</th>
                          <th scope="col">STATUS</th>
                          <th scope="col">ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                        {branchMenuData !== null &&
                          (branchMenuData as any[]).map((item, index) => (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{item.menuName}</td>
                              <td>{item.menuDescription}</td>
                              <td>
                                {item.isActive ? (
                                  <span className="badge bg-success rounded-pill">
                                    Active
                                  </span>
                                ) : (
                                  <span className="badge bg-error rounded-pill">
                                    InActive
                                  </span>
                                )}
                                &nbsp;
                              </td>
                              <td>
                                <a
                                  onClick={() => prepareEditBranchMenu(item)}
                                  className="btn-action rounded-circle act-btn "
                                >
                                  <i className="bi bi-pencil-square "></i>
                                </a>
                                &nbsp;
                                <a
                                  onClick={() => handleDeleteShow(item._id)}
                                  className="btn-action rounded-circle act-btn "
                                >
                                  <i className="bi bi-trash "></i>
                                </a>
                                &nbsp;
                                <a
                                  onClick={() =>
                                    handleViewBranchMenuShow(
                                      item._id,
                                      item.menuTypeCode
                                    )
                                  }
                                  className="btn-action rounded-circle act-btn "
                                >
                                  <i className="bi bi-eye "></i>
                                </a>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <div className=" d-flex  justify-content-end pe-md-5">
                      {!loading && (
                        <Pagination
                          currentPage={currentPage}
                          totalPages={totalPages}
                          setCurrentPage={setCurrentPage}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      )}
      {showModal && (
        <div
          className="modal"
          id="dish-modal"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          tabIndex={-1}
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Branch Menu Details
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleClose}
                ></button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col">
                      <table className="table table-bordered">
                        <p>
                          <b>
                            Welcome to our platform! To get started, please
                            select the menu you've created for each of your
                            restaurant branches.
                          </b>
                        </p>
                        <p>
                          <b>
                            Step 1: Menu Selection
                            <br />
                          </b>
                          Choose Menu: Select the corresponding menu you've
                          created for this branch.
                          <br />
                          <b>Step 2: Branch Selection</b>
                          <br />
                          Select a Branch: Choose the restaurant branch you want
                          to manage.
                          <br />
                          <b>Step 3: Confirmation</b>
                          <br />
                          Confirm: Once you've selected the branch and menu,
                          click 'Confirm' to save your selection.
                          <br />
                          <b>Step 4.: Repeat for Multiple Branches</b>
                          <br />
                          If you have multiple branches, repeat the process for
                          each branch by selecting the branch and its
                          corresponding menu.
                          <br />
                          <b>Step 5: Done!</b>
                          <br />
                          You're all set! Your menus are now linked to their
                          respective restaurant branches. You can always come
                          back here to update or change.
                          <br />
                        </p>
                        <tbody>{renderInputs()}</tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    onClick={addOrUpdateBranchMenu}
                    className="btn btn-primary"
                  >
                    Save changes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />

      {showDeleteModal && (
        <div
          className="modal"
          tabIndex={-1}
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Delete branch?
                </h1>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleDeleteClose}
                ></button>
              </div>
              <div className="delete-text">
                {" "}
                Are you sure you want to delete?
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleDeleteClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={deleteBranchMenu}
                  className="btn btn-primary"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showViewBranchModal && (
        <div
          className="modal modal-lg"
          tabIndex={-1}
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Branch menu details
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleViewBranchClose}
                ></button>
              </div>
              <div className="delete-text">
                <div className="mb-12 col-md-12">
                  <table className="table table-bordered view-table infoTable">
                    <tbody>
                      <tr>
                        <th>MENU NAME</th>
                        <td>{branchMenuDetails?.menuName}</td>
                        <th>STATUS </th>
                        <td>
                          <div>
                            {branchMenuDetails?.isActive ? (
                              <span className="badge bg-success rounded-pill">
                                Active
                              </span>
                            ) : (
                              <span className="badge bg-error rounded-pill">
                                InActive
                              </span>
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>DESCRIPTION </th>
                        <td>{branchMenuDetails?.menuDescription}</td>
                        {menuTypeCode === "buffetMenu" && (
                          <>
                            <th>IMAGE</th>
                            <td>
                              <div className="col-md-6 col-sm-12">
                                <img
                                  src={Image}
                                  height={110}
                                  width={130}
                                  alt="MenuDealz"
                                ></img>
                              </div>
                            </td>
                          </>
                        )}
                      </tr>
                      <tr>
                        <th>Menu</th>
                        <td colSpan={3}>
                          <table className="sub-table">
                            <thead>
                              <tr>
                                <th>BRANCH</th>

                                <th>ACTION</th>
                              </tr>
                            </thead>
                            <tbody>
                              {remainingBranches !== null &&
                                remainingBranches.map((item, index) => (
                                  <tr key={index}>
                                    <td>{item.name}</td>

                                    <td>
                                      <a
                                        onClick={() => {
                                          if (
                                            branchMenuDetails?.menuId !==
                                            undefined
                                          ) {
                                            viewBranchMenu(
                                              item._id,
                                              branchMenuDetails?.menuId
                                            );
                                          }
                                        }}
                                        className="btn-action rounded-circle act-btn "
                                      >
                                        <i className="bi bi-eye "></i>
                                      </a>
                                      &nbsp;
                                      <a
                                        onClick={() => {
                                          // handleDeleteBranch(item._id);
                                          handleDeleteBranchShow(item._id);
                                        }}
                                        className="btn-action rounded-circle act-btn"
                                      >
                                        <i className="bi bi-trash"></i>
                                      </a>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      {/* {remainingBranches !== null &&
                        remainingBranches.map((item, index) => (
                          <tr key={index}>
                            <th>BRANCH</th>
                            <td>{item.name}</td>
                            <th>MENU</th>
                            <td>
                              <Button
                                style={{ background: "rgb(18, 153, 1)", color: "white" }}
                                onClick={() => {
                                  if (branchMenuDetails?.menuId !== undefined) {
                                    viewBranchMenu(item._id, branchMenuDetails?.menuId);
                                  }
                                }}
                              >
                                View menu
                              </Button>
                              <Button
                                style={{ background: "rgb(220, 53, 69)", color: "white", marginLeft: "10px" }}
                                onClick={() => {
                                  handleDeleteBranch(item._id);
                                }}
                              >
                                Delete
                              </Button>
                            </td>
                          </tr>
                        ))} */}
                    </tbody>
                  </table>
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <ul className="nav nav-tabs" id="myTab" role="tablist">
                        {selectedCategories.map((category, index) => (
                          <li
                            className="nav-item"
                            role="presentation"
                            key={index}
                          >
                            <button
                              className={`nav-link ${
                                index === 0 ? "active" : ""
                              }`}
                              id={`tab${index}-tab`}
                              data-bs-toggle="tab"
                              data-bs-target={`#tab${index}`}
                              type="button"
                              role="tab"
                              aria-controls={`tab${index}`}
                              aria-selected={index === 0 ? "true" : "false"}
                              style={{ backgroundColor: "aliceBlue" }}
                            >
                              {getCategoryName(category)}
                            </button>
                          </li>
                        ))}
                      </ul>
                      <div className="tab-content" id="myTabContent">
                        {renderbranchmenu()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleViewBranchClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={UpdateBranchMenu}
                  className="btn btn-primary"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showDeleteBranchModal && (
        <div
          className="modal"
          tabIndex={-1}
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Delete Branch Menu?
                </h1>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleDeleteBranchClose}
                ></button>
              </div>
              <div className="delete-text">
                {" "}
                Are you sure you want to delete?
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleDeleteBranchClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={handleDeleteBranch}
                  className="btn btn-primary"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Dish;
