import { useEffect, useState } from "react";
import api from "../../api";
import Loader from "../Loader";
import { toast } from "react-toastify";
import { message } from "antd";
import Select from 'react-select';

interface ChildComponentProps {
  handleRegisterClose: () => void;
  SetPayloaddata: (formData: any) => void;
  SetMenuID: (id: string) => void;
}

interface Option {
  value: string;
  label: string;
  code: string;
  description: string;
  price: number;
}

interface Item {
  _id: string;
  name: string;
  code: string;
  description: string;
  price: number;
}

const DishRegister = (props: ChildComponentProps) => {
  const { handleRegisterClose, SetPayloaddata, SetMenuID } = props;
  const [loading, setLoading] = useState(false);

  const [defaultCurrency, setDefaultCurrency] = useState("");
  const [errors, setErrors] = useState<{ name?: string;
    //  description?: string;
       offerPrice?: string; selectedDishId?: string; 
    // quantity?: string
  }>({});
  const [dishOptions, setDishOptions] = useState<Option[]>([]);
  const [formData, setFormData] = useState({
    typeCode: "comboSpecialOffer",
    name: "",
    description: "",
    totalPrice: null,
    offerPrice: 0,
    dish: [
      {
        selectedDishId: "",
        quantity: null
      }
    ]
  });

  const handleChange = (selectedOption: any, index: number) => {
    const selectedValue = selectedOption ? selectedOption.value : '';
    setFormData((prevFormData) => ({
      ...prevFormData,
      dish: prevFormData.dish.map((dish, i) => 
        i === index ? { ...dish, selectedDishId: selectedValue } : dish
      )
    }));
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]:
        name === "totalPrice" || name === "offerPrice"
          ? parseFloat(value) || null // Set value to 0 if it's NaN
          : value,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validate()) {
      return;
    }
    const postData = {
      typeCode: formData.typeCode,
      name: formData.name,
      description: formData.description,
      totalPrice: formData.totalPrice,
      offerPrice: formData.offerPrice,
      dish: formData.dish.map((dish) => ({
        selectedDishId: dish.selectedDishId,
        quantity: dish.quantity,
      })),
    };

    try {
      const response = await api.post("/api/restaurant/special-offer/", postData);
      SetPayloaddata(formData)
      SetMenuID(response.data.data._id)
      setFormData({
        typeCode: "comboSpecialOffer",
        name: "",
        description: "",
        totalPrice: null,
        offerPrice: 0,
        dish: [
          {
            selectedDishId: "",
            quantity: null
          }
        ]
      });

      message.success(response.data.message);
      handleRegisterClose();
    } catch (error: any) {
      console.error("There was an error!", error);

      if (error.response) {
        message.error(error.response.data.message);
      } else {
        message.error("Please fill out all required fields.");
      }
    }
  };

  useEffect(() => {
    const loggedInUserData = localStorage.getItem("loggedInUser");
    if (loggedInUserData) {
      const parsedData = JSON.parse(loggedInUserData);
      setDefaultCurrency(parsedData.restaurantdefaultCurrencyCode);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => setLoading(false), 3300);
    getAllActiveDishes();
  }, []);

  if (loading) {
    return <Loader />;
  }

  const handleAddDish = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      dish: [
        ...prevFormData.dish,
        { selectedDishId: "", quantity: 0 }
      ],
    }));
  };

  const handleRemoveDish = (index: number) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      dish: prevFormData.dish.filter((_, i) => i !== index),
    }));
  };

  const handleDishesChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value } = e.target;

    const updatedDishes = formData.dish.map((dish, i) => {
      if (i === index) {
        return {
          ...dish,
          [name]: name === "quantity" ? parseFloat(value) : value,
        };
      }
      return dish;
    });

    setFormData({
      ...formData,
      dish: updatedDishes,
    });
  };

  const getAllActiveDishes = async () => {
    try {
      const response = await api.get("/api/restaurant/dish/active?page=1&limit=1000");
      const formattedOptions = response.data.data.dish.map((item: Item) => ({
        value: item._id,
        label: item.name,
        code: item.code,
        description: item.description,
      }));
      setDishOptions(formattedOptions);
    } catch (error) {
      console.error("Error fetching dish data:", error);
      message.error("Error fetching dish data!");
    }
  };
  
  const validate = (): boolean => {
    const newErrors: { 
        name?: string; 
        // description?: string; 
        offerPrice?: string; 
        selectedDishId?: string; 
        // quantity?: string 
    } = {};

    if (!formData.name.trim()) {
        newErrors.name = "Name required.";
    }
    // if (!formData.description.trim()) {
    //     newErrors.description = "Description required.";
    // }
   
    if (!formData.offerPrice) {
        newErrors.offerPrice = "Offer Price required.";
    }

    formData.dish.forEach((dish, index) => {
        if (!dish.selectedDishId) {
            newErrors[`selectedDishId${index}`] = "Dish selection required.";
        }
        // if (!dish.quantity) {
        //     newErrors[`quantity${index}`] = "Quantity required.";
        // }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
};

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="modal-body">
          <div className="row">
            <div className="col-md-6 mb-3">
              <label htmlFor="userName" className="form-label">Name Of The Combo Offer<span className="text-danger"> *</span></label>
              <input
                type="text"
                placeholder="Enter Name Of The Combo Offer"
                name="name"
                className="form-control"
                id="userName"
                value={formData.name}
                onChange={handleInputChange}
              />
              {errors.name && <div className="text-danger">{errors.name}</div>}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="mb-3">
                <label className="form-label">
                  Description
                </label>
                <textarea
                  className="form-control"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  rows={2}
                  placeholder="Enter description about the offer"
                ></textarea>
                {/* {errors.description && <div className="text-danger">{errors.description}</div>} */}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <table className="table table-bordered">
                <tbody>
                  {formData.dish.map((dish, index) => (
                    <tr key={index}>
                      <td>
  <label className="form-label">
    Select The Dish<span className="text-danger"> *</span>
  </label>
  <Select
    value={dishOptions.find(option => option.value === dish.selectedDishId)}
    onChange={(selectedOption) => handleChange(selectedOption, index)}
    options={dishOptions}
    getOptionLabel={(option) => option.label}
    getOptionValue={(option) => option.value}
    isClearable
    placeholder="Select"
  />
  {errors[`selectedDishId${index}`] && <div className="text-danger">{errors[`selectedDishId${index}`]}</div>}
</td>
                      <td>
                        <label>Quantity</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Quantity"
                          name="quantity"
                          value={dish.quantity}
                          onChange={(e) => handleDishesChange(e, index)}
                        />
                       {/* {errors[`quantity${index}`] && <div className="text-danger">{errors[`quantity${index}`]}</div>} */}
                      </td>
                      <td>
                        {index > 0 && (
                          <button
                            type="button"
                            className="btn btn-danger rounded-circle"
                            onClick={() => handleRemoveDish(index)}
                          >
                            <i className="bi bi-file-minus"></i>
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="col-1">
              <button
                type="button"
                className="btn btn-primary rounded-circle"
                onClick={handleAddDish}
              >
                <i className="bi bi-file-plus"></i>
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 mb-3">
              <label htmlFor="totalPrice" className="form-label">Total Price ({defaultCurrency})</label>
              <input
                type="text"
                placeholder="Enter Total Price"
                name="totalPrice"
                className="form-control"
                id="totalPrice"
                value={formData.totalPrice}
                onChange={handleInputChange}
              />
              
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="offerPrice" className="form-label">Offer Price ({defaultCurrency})<span className="text-danger"> *</span></label>
              <input
                type="text"
                placeholder="Enter Offer Price"
                name="offerPrice"
                className="form-control"
                id="offerPrice"
                value={formData.offerPrice}
                onChange={handleInputChange}
              />
              {errors.offerPrice && <div className="text-danger">{errors.offerPrice}</div>}
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="submit" className="btn btn-primary">
            Save & Next <i className="bi bi-caret-right-fill"></i>
          </button>
        </div>
      </form>
    </>
  );
};

export default DishRegister;
