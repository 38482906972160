import React from "react";
import ReactPaginate from "react-paginate";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  setCurrentPage: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  setCurrentPage,
}) => {
  const pageRangeDisplayed = 3; // Display three number buttons
  const handlePageClick = (data: { selected: number }) => {
    const selectedPage = data.selected + 1;
    setCurrentPage(selectedPage);
  };

  return (
    <div className="pagination-container">
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        pageCount={totalPages}
        pageRangeDisplayed={pageRangeDisplayed}
        marginPagesDisplayed={0} // No pages displayed at the margins
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
        pageLinkClassName={"pagination-box"}
        previousLinkClassName={"pagination-box"}
        nextLinkClassName={"pagination-box"}
        disabledClassName={"disabled"}
        forcePage={currentPage - 1}
      />
    </div>
  );
};

export default Pagination;
